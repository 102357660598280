import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../scss/ExceptionalWork.scss";
import WorkInner from "./WorkInner.js";
// import Categories from "./Categories.js";

const $ = window.$;

class ExceptionalWorkApi extends React.Component {

    componentDidMount() {

        // Jquery
        var screen = $(window).width();

        this.props.dispatch({ type: "SHOW_MAP", payload: false });
        this.props.dispatch({ type: "SHOW_LOGO", payload: true });
        this.props.dispatch({ type: "SHOW_GALLERY", payload: false });
        this.props.dispatch({ type: "SHOW_WORK", payload: true });
        this.props.dispatch({ type: "SHOW_BACKGROUND", payload: false });

        if (screen <= 576) {
            this.props.dispatch({ type: "SHOW_LOGO", payload: false });
        }

        // $(document).ready(function() {
        //     $("html,body").animate({ scrollTop: 0 }, 100);
        // });

        // window.onscroll = () => {
        //     if (window.scrollY > 50) {
        //         $(".box-1-mobile .container-text-front").addClass("gone");
        //         $(".box-1-mobile .container-text-back").addClass("display");
        //         $(".box-1-mobile .container-mobile").addClass("display");
        //     } else {
        //         $(".box-1-mobile .container-text-front").removeClass("gone");
        //         $(".box-1-mobile .container-text-back").removeClass("display");
        //         $(".box-1-mobile .container-mobile").removeClass("display");
        //     }
        // };
        // $(".categories").on("click", "a", function() {
        //     $(".categories a.active").removeClass("active");
        //     $(this).addClass("active");
        // });
    }

    render() {
        return (
            <div className="section-exceptionalwork">
                <WorkInner />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        showMap: state.showMap,
        showLogo: state.showLogo,
        showWork: state.showWork,
        showGallery: state.showGallery,
        showBackground: state.showBackground

    };
}

export default connect(mapStateToProps)(ExceptionalWorkApi);
