import React from "react";
import Slider from "react-slick";
import "../scss/Carousel.scss";
import { element } from "prop-types";


const $ = window.$;

function BtnNext(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function BtnPrev(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function stopVideo() {
  const videos = document.querySelectorAll('.video-carousel');
  videos.forEach((video) => {
      video.pause();
      video.currentTime = 0;
  });
}

function autoplayVideo() {
  const videos = document.querySelectorAll('.video-carousel');
  videos.forEach((video) => {
      video.autoplay = true;
      video.load();
  });
}

function reloadVideo() {
  const videos = document.querySelectorAll('.video-carousel');
  videos.forEach((video) => {
      video.load();
  });
}

export default class SimpleSlider extends React.Component {
    constructor(props) {
        super(props);

        // this.next = this.next.bind(this);
        // this.previous = this.previous.bind(this); 
        // this.resetSlideGallery = this.resetSlideGallery.bind(this);
        
        this.state = {
          nav1 : null,
          nav2 : null,
          i : 1
        }

        this.slider = React.createRef();
    }

    resetSlideGallery() {
        this.slider1.slickGoTo(0);
        this.slider2.slickGoTo(0);
        this.setState({
          i:1
        })
    }

    // next() {
    //     stopVideo();
    //     this.slider.slickNext();
    // }

    // previous() {
    //     stopVideo();
    //     this.slider.slickPrev();
    // }

    onHover = () => {
        // console.log('hover!')
        const btn = document.querySelectorAll(".container-carousel .btn");
        // $(btn).css("opacity", 0.3);
        if ($(".slick-slide").length > 1) {
            $(btn).css("opacity", 0.3);
        } else {
            $(btn).css("opacity", 0);
        }
    };

    outHover = () => {
        // console.log('out!')
        const btn = document.querySelectorAll(".container-carousel .btn");
        $(btn).css("opacity", 0);
    };

    onHoverButtonPrev = () => {
        const btn = document.querySelectorAll(".container-carousel .btn.prev");
        // $(btn).css("opacity", 1);
        if ($(".slick-slide").length > 1) {
            $(btn).css("opacity", 1);
        } else {
            $(btn).css("opacity", 0);
        }
    };

    onHoverButtonNext = () => {
        const btn = document.querySelectorAll(".container-carousel .btn.next");
        // $(btn).css("opacity", 1);
        if ($(".slick-slide").length > 1) {
            $(btn).css("opacity", 1);
        } else {
            $(btn).css("opacity", 0);
        }
    };

    componentDidMount() {
      this.setState({
        nav1 : this.slider1,
        nav2 : this.slider2,
        i: 1
      })      
    }

    componentDidUpdate(){

      // const i = $('.slick-current').attr('data-index');
      // const x = parseInt(i) + 1
      // this.state.i = x
      reloadVideo()
      
      const videos = document.querySelector('.slick-slider:nth-child(1) .slick-current video');
      // console.log('componentDidUpdate', videos, this.props.isOpenPortal)
      
      if(!this.props.isOpenPortal) { //portal is close
          // console.log('stop')
          // this.setState({
          //   i : 1
          // })
      } else { //portal is open
          // console.log('play', videos)

          if (videos) { //open & video
            videos.load()
            videos.play()
            // console.log('play...')
          } 
      }

            
    }

    render() {

        // get total length
        const img = this.props.images.length ? this.props.images.length : 0 
        const video = this.props.videos.length ? this.props.videos.length : 0
        const total = img+video   

        // const elements = document.querySelectorAll('.slide-preview .slick-list .slick-track .slick-slide')
        // elements.forEach((element) => {
          const currentElement = document.querySelector('.slide-preview .slick-list .slick-track .slick-slide.slick-active.slick-current')
          // const currentIndex = currentElement.getAttribute('data-index')
          // if(currentElement) {
          //   const currentIndex = $(this).data("data-index")
          //   console.log(currentElement, currentIndex)
          // }
        // })
        // console.log(elements.length)


        const settings = {
          // dots: true,
          nextArrow: <BtnNext />,
          prevArrow: <BtnPrev />,
          beforeChange: (index) => {
            stopVideo();      
            // this.setState({ i: index + 1})
         
          },
          afterChange: (index) => {
            this.setState({ i: index + 1})
            // console.log(index)
            // this.state.i = index;
            const videos = document.querySelector('.slick-slider:nth-child(1) .slick-slide.slick-active.slick-current video');
            // console.log('xxx', videos)
            if(!this.props.isOpenPortal) {
              if (videos) {
                videos.pause()
                videos.currentTime = 0;
                // console.log('work paused')
              }
            }
            else {
              if (videos) {
                videos.load()
                videos.play()
                // console.log('work play')
              }
            }
            // if (videos) {
            //   videos.play()
            //   console.log('work')
            // } else {

            // }
            
          }
        }

        return (
            <div
                className="container-carousel"
                onMouseEnter={this.onHover}
                onMouseLeave={this.outHover}
            >
                <Slider {...settings}
                  className="slide-preview"
                  ref={slider => (this.slider1 = slider)} 
                  asNavFor={this.state.nav2}
                  arrows={true}
                  fade={true}
                  infinite={true}
                >
                    {this.props.images &&
                        this.props.images.length > 0 &&
                        this.props.images.map((image, i) => {
                            return (
                                <div key={i} className="img-carousel">
                                    <img src={image.url} />
                                </div>
                            );
                        })}
                    {this.props.videos &&
                        this.props.videos.length > 0 &&
                        this.props.videos.map((video, i) => {
                            return (
                                <div key={i} className="img-carousel video">
                                    <video
                                        className="video-carousel"
                                        // src={video.video}
                                        poster={video.poster}
                                        controls
                                        
                                    >
                                      <source src={video.video} type="video/mp4"/>
                                    </video>
                                </div>
                            );
                            
                        })}
                </Slider>
                <Slider 
                  ref={slider => (this.slider2 = slider)} 
                  asNavFor={this.state.nav1}
                  slidesToShow={5} 
                  slidesToScroll={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  adaptiveHeight={true}
                  arrows={false}
                //   centerMode={true}
                  // infinite={true}

                >
                    {this.props.images &&
                        this.props.images.length > 0 &&
                        this.props.images.map((image, i) => {
                            return (
                                <div key={i} className="img-carousel-preview">
                                    <img src={image.url} alt=''/>
                                </div>
                            );
                        })}
                    {this.props.videos &&
                        this.props.videos.length > 0 &&
                        this.props.videos.map((video, i) => {
                            return (
                                // <div key={i} className="img-carousel-preview video">
                                //     <video
                                //         className="video-carousel"
                                //         src={video.video}
                                //         controls
                                //     ></video>
                                // </div>
                                <div key={i} className="img-carousel-preview video">
                                    <img src={video.poster} alt=''/>
                                </div>
                            );
                        })}
                </Slider>
                
                <div className="count-element">
                  <p>{this.state.i} / {total}</p>
                </div>
            </div>
        );
    }
}
