import React from "react";
import styled from "styled-components";
import { TimelineMax, Circ } from "gsap/all";
import { HashLink as Link } from "react-router-hash-link";
import smoothscroll from 'smoothscroll-polyfill';
import "../scss/Navigation.scss";
import "../js/navigationPixi.js";
import navi from "../js/navigationPixi.js";
import { connect } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const $ = window.$;
const Plus = styled.img`
  width: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: all .5s ease-out;

  ${props =>
    props.p1 &&
    `
        top: 0;
        left: 0;
        transform: unset;
    `}
  ${props =>
    props.p2 &&
    `
        top: 0;
        left: calc(100% - 18px);
        transform: unset;
    `}
  ${props =>
    props.p3 &&
    `
        left: 0;
        top: calc(100% - 18px);
        transform: unset;
    `}
  ${props =>
    props.p4 &&
    `
        left: calc(100% - 18px);
        top: calc(100% - 18px);
        transform: unset;
    `}
`;

const BoxLogo = styled.div`
  position: fixed;
  right: 0;
  margin: 20px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 5;
  top: 0;

  img {
    height: 45px;
  }

  ${props =>
    props.boxLogo &&
    `
        opacity: 0;
    `}
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false
    };

    this.tl = new TimelineMax({
      paused: true,
      // reverse: true,
      // onStart: ()=> {
      //   document.querySelector(".main-nav-mobile .wrapper-data").scrollTo(0, 0);

      // }
      onReverseComplete: () => {
        document.querySelector(".main-nav-mobile .wrapper-data").scrollTo(0, 0);
      //   $(document).ready(function() {
      //     $(".main-nav-mobile .wrapper-data").animate({ scrollTop: 0 }, 100);
      //   });
      }
    });

    smoothscroll.polyfill();
  }

  componentDidMount() {

    window.addEventListener("load", function() {
        var canvas = document.createElement("canvas");
        // Get WebGLRenderingContext from canvas element.
        var gl = canvas.getContext("webgl")
          || canvas.getContext("experimental-webgl");
        // Report the result.
        if (gl && gl instanceof WebGLRenderingContext) {

          if($(window).width() >= 1200) {
            // console.log(window.innerWidth, 'size')
            navi();
            const nav = document.querySelector('nav.main-nav-mobile');
            nav.style.display = "none";
          } else {
            // console.log(window.innerWidth, 'size else')
            const nav = document.querySelector('nav.main-nav-mobile');
            nav.style.display = "block";

            const nav2 = document.querySelector('nav.main-nav');
            nav2.style.display = "none";

          }
        } else { // cant use webgl

          const nav = document.querySelector('nav.main-nav-mobile');
          nav.style.display = "block";

          const nav2 = document.querySelector('nav.main-nav');
          nav2.style.display = "none";
        }
    }, false);
    // navi();
      this.createMenuTween();    
  }

  // handleClickLinkScrollToSection = (section) => {
  //   window.scrollTo(0, $('#'+section).offset().top);
  // }

  handleMenuClick = () => {
    // setButton
    this.setState(({ isToggleOn }) => ({
      isToggleOn: !isToggleOn
    }));

    const { isToggle } = this.state;
    this.setState({ isToggle: !isToggle });

    document.querySelector(".main-nav-mobile .wrapper-data").scrollTo(0, 0);

    // var screen = $(window).width();
    // if (screen <= 1200) {
      // this.tl.reversed(isToggle);
      // this.tl.play()
    // }

    // const html = document.querySelector("html");
    if (!this.state.isToggle) {
      this.tl.play()
      disableBodyScroll(document.querySelector(".main-nav-mobile .wrapper-data"))
      this.tl.play()
      // console.log('disable')
    } else {
      enableBodyScroll(document.querySelector(".main-nav-mobile .wrapper-data"))
      // console.log('enable')

      this.tl.seek(0)
      this.tl.pause()
    }
  };

  closeNav = () => {
    // setButton
    this.setState(({ isToggleOn }) => ({
      isToggleOn: false
    }));

    document.getElementById("main-nav-toggle").checked = false;

    const { isToggle } = this.state;
    enableBodyScroll(document.querySelector(".main-nav-mobile .wrapper-data"))
    // console.log('enable')

    this.setState({ isToggle: false });
    this.tl.seek(0)
    this.tl.pause()
    // var screen = $(window).width();
    // if (screen <= 1200) {
    //   this.tl.reversed(true);
    // }
  };

  createMenuTween = () => {
    this.tl
      .to(".main-nav-mobile .wrapper",
      0.8, {
        opacity: 1,
        ease: Circ.easeOut,
        // delay: 0.1
      })
      .staggerFrom(
        ".main-nav-mobile .wrapper .wrapper-data .container-link .box-link",
        1.4,
        {
          yPercent: 100,
          opacity: 0,
          ease: Circ.easeOut,
          delay: 0.8
        },
        0.1,
      )
      .pause()
  };


  render() {
    const { isToggleOn } = this.state;

    return (
      <>
        <div className="navigation">
            <div className="box-btn">
              <input type="checkbox" id="main-nav-toggle" />
              <label
                className="group-btn"
                htmlFor="main-nav-toggle"
                onClick={this.handleMenuClick}
              >
                <Plus
                  p1={!this.state.isToggleOn}
                  src="/assets/plus.svg"
                  className="p_1"
                />
                <Plus
                  p2={!this.state.isToggleOn}
                  src="/assets/plus.svg"
                  className="p_2"
                />
                <Plus
                  p3={!this.state.isToggleOn}
                  src="/assets/plus.svg"
                  className="p_3"
                />
                <Plus
                  p4={!this.state.isToggleOn}
                  src="/assets/plus.svg"
                  className="p_4"
                />
              </label>
              <nav className="main-nav" onClick={this.closeNav}>
                <ul className="navi-smooth">
                  <li>
                    <Link smooth to="/#home">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#whatwedo">
                      WHAT WE DO
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#work">
                      WORK
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#whyus">
                      WHY US
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#service">
                      SERVICE
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#office">
                      OFFICE
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#client">
                      CLIENT
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/#contact">
                      CONTACT
                    </Link>
                  </li>
                  {/* <li>
                    <Link smooth to="/jobs">JOB</Link>
                  </li> */}
                </ul>
              </nav>
            </div>

            {this.props.showLogo && (
              <BoxLogo>
                <Link mooth to="/#home">
                  <img className="logo-img" src="/assets/head_2.svg" />
                </Link>
              </BoxLogo>
            )}
          {/* </div> */}
        </div>
        <nav className={this.state.isToggle ? "main-nav-mobile active" : "main-nav-mobile"} onClick={this.closeNav}>
          <div className="wrapper">
            <div className="wrapper-data" data-scrollbar="true">
              <div className="container-link navi-smooth">
                <div className="box-link">
                  <Link smooth to="/#home" className="item-link">
                    <span className="title">HOME</span>
                    <span className="title-number">01</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#whatwedo" className="item-link">
                    <span className="title">WHAT WE DO</span>
                    <span className="title-number">02</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#work" className="item-link">
                    <span className="title">WORK</span>
                    <span className="title-number">03</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#whyus" className="item-link">
                    <span className="title">WHY US</span>
                    <span className="title-number">04</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#service" className="item-link">
                    <span className="title">SERVICE</span>
                    <span className="title-number">05</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#office-1" className="item-link">
                    <span className="title">OFFICE</span>
                    <span className="title-number">06</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#client" className="item-link">
                    <span className="title">CLIENT</span>
                    <span className="title-number">07</span>
                  </Link>
                </div>
                <div className="box-link">
                  <Link smooth to="/#contact" className="item-link">
                    <span className="title">CONTACT</span>
                    <span className="title-number">08</span>
                  </Link>
                </div>
                {/* <div className="box-link">
                  <Link smooth to="/jobs" className="item-link">
                    <span className="title">JOB</span>
                    <span className="title-number">09</span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLogo: state.showLogo
  };
}

export default connect(mapStateToProps)(Navigation);
