import React from "react";
import qwest from "qwest";
import Portal from "./PortalGallery.js";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import { disableBodyScroll } from "body-scroll-lock";

import "../scss/WorkInner.scss";

const $ = window.$;
const api = {
    // baseUrl: "https://bcrumbapp.com/wp-json/conspiracy/v1/posts?per_page=10&is_work=yes"
    baseUrl:
        "https://www.conspiracyplus.com/api/wp-json/conspiracy/v1/posts?per_page=10&is_work=yes"
};

class GridWork extends React.Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.isCategoryClicked = false;
        this.prevCategory = "";
        this.portal = React.createRef();

        this.state = {
            // isPreview: false,
            // isNext: false,
            // isPrev: false,

            isOpen: false,

            portfolios: [],
            // currentIndex: 1,
            hasMoreItems: true,
            nextHref: null,
            pageIndex: 1,
            title: "Title",
            company: "Company",
            categories: "",
            gallery: [],
            video: [],

            img: "Image",
            // count: 0

            isCategoryClicked: false,

            prevEvent: "",
            nextEvent: ""
        };

        this.loadItems = this.loadItems.bind(this);
        this.handleOpenClick = this.handleOpenClick.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);
    }

    // componentWillMount() {
    //     this.loadItems();
    // }

    loadItems() {
        var self = this;
        var url = api.baseUrl;

        if (this.state.nextHref) {
            url = this.state.nextHref;
        }

        qwest
            .get(
                api.baseUrl,
                {
                    page: this.state.pageIndex,
                    category_name: this.state.categories
                },
                {
                    cache: true
                }
            )
            .then(function(xhr, resp) {
                if (resp) {
                    var portfolios = self.state.portfolios;
                    resp.map(portfolio => {
                        var dataArr = {};
                        dataArr.id = portfolio.id;
                        dataArr.title = portfolio.title;
                        dataArr.image = portfolio.image;
                        dataArr.company = portfolio.acf.company;
                        dataArr.gallery = portfolio.acf.gallery;
                        dataArr.logo = portfolio.acf.logo;
                        dataArr.video = portfolio.acf.video_gallery;
                        dataArr.online = portfolio.acf.online_category;
                        dataArr.categories = portfolio.categories[0].name;

                        portfolios.push(dataArr);
                    });
                    // console.log(portfolios.length)

                    self.setState({
                        pageIndex: self.state.pageIndex + 1
                    });

                    if (resp.length < 10) {
                        self.setState({ hasMoreItems: false });
                    }

                    clearTimeout(self.timer);
                    self.timer = setTimeout(() => {
                        self.isCategoryClicked = false;
                        // self.setState({
                        //     isCategoryClicked: false
                        // });
                    }, 2000);
                }
            })
            .catch(function(error) {
                self.setState({ hasMoreItems: false });
            });
    }

    handleOpenClick(e) {
        e.preventDefault();
        var i = e.target.getAttribute("data-index");

        // this.refs.portal.toggleModal(i);
        this.portal.current.toggleModal(i);

        const navigation = document.querySelector(".navigation");
        navigation.style.display = "none";

        disableBodyScroll(document.querySelector(".portal"));
        document.querySelector(".portal").scrollTo(0, 0);

        document.querySelector("html").style.overflowY = "hidden";
    }

    handleCategoryClick(e) {
        if (this.prevCategory !== e) {
            this.isCategoryClicked = false;
        }
        if (!this.isCategoryClicked) {
            this.doClickCategory(e);
        }
        this.prevCategory = e;
    }

    doClickCategory(category_name) {
        // console.log(".categories a."+category_name)
        this.isCategoryClicked = true;
        switch (category_name) {
            case "":
                this.setState({
                    portfolios: [],
                    currentIndex: 1,
                    hasMoreItems: true,
                    nextHref: null,
                    pageIndex: 1,
                    isToggleOn: false,
                    title: "Title",
                    company: "Company",
                    categories: "",

                    gallery: [],
                    video: [],

                    img: "Image",
                    isCategoryClicked: true
                });
                // console.log("all..");
                // console.log(this.state.portfolios)
                break;

            case "branding":
                this.setState({
                    portfolios: [],
                    currentIndex: 1,
                    hasMoreItems: true,
                    nextHref: null,
                    pageIndex: 1,
                    isToggleOn: false,
                    title: "Title",
                    company: "Company",
                    categories: "branding",

                    gallery: [],
                    video: [],

                    img: "Image",
                    isCategoryClicked: true
                });
                break;

            case "video":
                this.setState({
                    portfolios: [],
                    currentIndex: 1,
                    hasMoreItems: true,
                    nextHref: null,
                    pageIndex: 1,
                    isToggleOn: false,
                    title: "Title",
                    company: "Company",
                    categories: "video",

                    gallery: [],
                    video: [],

                    img: "Image",
                    isCategoryClicked: true
                });
                break;

            case "online":
                this.setState({
                    portfolios: [],
                    currentIndex: 1,
                    hasMoreItems: true,
                    nextHref: null,
                    pageIndex: 1,
                    isToggleOn: false,
                    title: "Title",
                    company: "Company",
                    categories: "online",

                    gallery: [],
                    video: [],

                    img: "Image",
                    isCategoryClicked: true
                });
                break;

            case "activation":
                this.setState({
                    portfolios: [],
                    currentIndex: 1,
                    hasMoreItems: true,
                    nextHref: null,
                    pageIndex: 1,
                    isToggleOn: false,
                    title: "Title",
                    company: "Company",
                    categories: "activation",

                    gallery: [],
                    video: [],

                    img: "Image",
                    isCategoryClicked: true
                });
                break;

            default:
            // console.log("ALL 2");
        }

        $(document).ready(function() {
            $("html,body").animate({ scrollTop: 0 }, 100);
            $(".categories a.active").removeClass("active");
            let category = category_name ? category_name : "all";
            $(".categories a." + category).addClass("active");
        });
    }

    componentDidUpdate() {
        // $(".categories").on("click", "a", function() {
        //     $(".categories a.active").removeClass("active");
        //     $(this).addClass("active");
        // });
        // const thumbnail = document.querySelectorAll('.thumbnail')
        // console.log('xxxxx',thumbnail)
        // thumbnail.forEach(function(el) {
        //     el.addEventListener('click', ()=> {
        //         alert(1234)
        //     })
        // })
        // .addEventListener('mouseover', ()=> {
        //     alert(1234);
        // })
    }

    onCloseModal = () => {
        this.setState({ isOpen: false });
    };

    componentDidMount() {
        let vh = $(window).height() * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        window.onscroll = () => {
            if (window.scrollY > 50) {
                $(".box-1-mobile .container-text-front").addClass("gone");
                $(".box-1-mobile .container-text-back").addClass("display");
                $(".box-1-mobile .container-mobile").addClass("display");
            } else {
                $(".box-1-mobile .container-text-front").removeClass("gone");
                $(".box-1-mobile .container-text-back").removeClass("display");
                $(".box-1-mobile .container-mobile").removeClass("display");
            }
        };

        // load specification category
        // console.log(window.location.hash.replace("#", ""));
        if (window.location.hash) {
            let category = window.location.hash.replace("#", "");
            this.handleCategoryClick(category);
        }
    }

    render() {
        const loader = (
            <div style={{ visibility: "hidden" }} className="loader">
                Loading ...
            </div>
        );
        //     const loader = <div style={{color: 'red', position: 'absolute', top: '50%', left: '50%',
        // transfrom: 'translate(-50%,-50%)'}} className="loader"><h1>Loading ...</h1></div>;

        var elements = [];
        // console.log('el ', elements)
        this.state.portfolios.map((portfolio, i) => {
            elements.push(
                <div
                    className="thumbnail"
                    style={{
                        backgroundImage: "url(" + portfolio.image + ")",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <img
                        src={portfolio.logo}
                        className="openPortal"
                        key={i}
                        data-index={i}
                        id={portfolio.id}
                        onClick={this.handleOpenClick}
                        alt=""
                    />
                </div>
            );
        });

        // console.log("elements ", this.state.portfolios.length);

        return (
            <>
                {this.props.showWork && (
                    <>
                        <div className="box-1">
                            <div className="container-text">
                                <h1>EXCEPTIONAL WORK</h1>
                            </div>
                        </div>
                        <div className="box-1-mobile">
                            <div className="container-text-front">
                                <h1>EXCEPTIONAL WORK</h1>
                            </div>

                            <div className="container-text-back">
                                <h1>EXCEPTIONAL WORK</h1>
                            </div>
                            <div className="container-mobile">
                                <ul className="categories" id="cate">
                                    <li>
                                        <a
                                            className="all active"
                                            onClick={() =>
                                                this.handleCategoryClick("")
                                            }
                                            href="/exceptionalwork#"
                                        >
                                            ALL CATEGORIES
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="video"
                                            onClick={() =>
                                                this.handleCategoryClick(
                                                    "video"
                                                )
                                            }
                                            href="/exceptionalwork#video"
                                        >
                                            VIDEO
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="branding"
                                            onClick={() =>
                                                this.handleCategoryClick(
                                                    "branding"
                                                )
                                            }
                                            href="/exceptionalwork#branding"
                                        >
                                            BRANDING
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            onClick={() =>
                                                this.handleCategoryClick(
                                                    "printedmedia"
                                                )
                                            }
                                        >
                                            PRINTED MEDIA
                                        </a>
                                    </li> */}
                                    <li>
                                        <a
                                            className="online"
                                            onClick={() =>
                                                this.handleCategoryClick(
                                                    "online"
                                                )
                                            }
                                            href="/exceptionalwork#online"
                                        >
                                            ONLINE
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="activation"
                                            onClick={() =>
                                                this.handleCategoryClick(
                                                    "activation",
                                                    this
                                                )
                                            }
                                            href="/exceptionalwork#activation"
                                        >
                                            ACTIVATION
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="box-2">
                            <div className="box-left">
                                <div className="container">
                                    <div className="text-header">
                                        <img
                                            src="/assets/plus_silver.svg"
                                            alt=""
                                        />
                                        <h4>VIEW BY</h4>
                                    </div>

                                    <ul>
                                        <li>
                                            {/* <p>/ CATEGORY</p> */}
                                            <ul
                                                className="categories"
                                                id="cate"
                                            >
                                                <li>
                                                    <a
                                                        className="all active"
                                                        onClick={() =>
                                                            this.handleCategoryClick(
                                                                ""
                                                            )
                                                        }
                                                        href="/exceptionalwork#"
                                                    >
                                                        + ALL CATEGORIES
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="video"
                                                        onClick={() =>
                                                            this.handleCategoryClick(
                                                                "video"
                                                            )
                                                        }
                                                        href="/exceptionalwork#video"
                                                    >
                                                        + VIDEO
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="branding"
                                                        onClick={() =>
                                                            this.handleCategoryClick(
                                                                "branding"
                                                            )
                                                        }
                                                        href="/exceptionalwork#branding"
                                                    >
                                                        + BRANDING
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        className="online"
                                                        onClick={() =>
                                                            this.handleCategoryClick(
                                                                "online"
                                                            )
                                                        }
                                                        href="/exceptionalwork#online"
                                                    >
                                                        + ONLINE
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        className="activation"
                                                        onClick={() =>
                                                            this.handleCategoryClick(
                                                                "activation",
                                                                this
                                                            )
                                                        }
                                                        href="/exceptionalwork#activation"
                                                    >
                                                        + ACTIVATION
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <Link to="/">
                                        <h1>BACK</h1>
                                    </Link>
                                </div>
                                <div />
                            </div>

                            <div className="box-right">
                                {/* <WorkInner /> */}
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadItems}
                                    hasMore={this.state.hasMoreItems}
                                    threshold={50}
                                    loader={loader}
                                >
                                    <div className="grid-exceptionalwork">
                                        {elements}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </>
                )}
                {this.state.portfolios.length > 0 && (
                    <Portal
                        isOpen={this.state.isOpen}
                        closeModal={this.onCloseModal}
                        portfolios={this.state.portfolios}
                        // hasMore={this.hasMore.bind(this)}
                        ref={this.portal}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showWork: state.showWork,
        showGallery: state.showGallery
    };
}

export default connect(mapStateToProps)(GridWork);
