import * as ScrollMagic from "scrollmagic";
import "../js/debug.addIndicators.js";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const controller = new ScrollMagic.Controller();

export function triggerX (el, d, x) {
    new ScrollMagic.Scene({
      triggerElement: "#trigger-service",
      triggerHook: 0,
      reverse: false
    })
      .setTween(el, {
        opacity: 1,
        delay: d,
        x: x,
        ease: Expo.easeOut
      })
    //   .addIndicators({name: "trigger X"})
      .addTo(controller);
  };

  export function triggerY(el, d, y) {
    new ScrollMagic.Scene({
      triggerElement: "#trigger-service",
      triggerHook: 0,
      reverse: false
    })
      .setTween(el, {
        opacity: 1,
        delay: d,
        y: y,
        ease: Expo.easeOut
      })
    //   .addIndicators({name: "trigger Y"})
      .addTo(controller);
  };

  export function triggerSlideX(el, triggerEl, x, d) {
    const slide = document.querySelectorAll(el);
    new ScrollMagic.Scene({
      triggerElement: triggerEl,
      triggerHook: 0,
      reverse: false
    })
      .setTween(
        TweenMax.staggerFrom(
          slide,
          1.75,
          {
            xPercent: x,
            delay: d,
            ease: Expo.easeOut
          },
          0.1
        )
      )
    //   .addIndicators({name: "Slide Left"})
      .addTo(controller);
  };