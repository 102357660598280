import React from "react";
import qwest from "qwest";
import { Link } from "react-router-dom";
import Portal from "./PortalGallery.js";
import { disableBodyScroll } from "body-scroll-lock";
import "../scss/WorkOutter.scss";

const api = {
    // baseUrl:
    //     "https://bcrumbapp.com/wp-json/conspiracy/v1/posts?per_page=11&orderby=meta_value_num&order=desc",
    baseUrl:
        "https://www.conspiracyplus.com/api/wp-json/conspiracy/v1/posts?per_page=11&orderby=meta_value_num&order=desc"
};

export default class WorkOutter extends React.Component {
    constructor(props) {
        // console.log('constructor..')

        super(props);

        this.portal = React.createRef();
        this.targetElement = null;

        this.state = {
            isPreview: false,
            isNext: false,
            isPrev: false,
            isOpen: false,

            portfolios: [],
            currentIndex: 1,
            hasMoreItems: true,
            nextHref: null,
            pageIndex: 1,
            order: "",
            title: "Title",
            company: "Company",
            content: "",
            categories: "",
            gallery: [],
            video: [],
            img: "Image",
            count: 0
        };

        this.loadItems = this.loadItems.bind(this);
        this.handleOpenClick = this.handleOpenClick.bind(this);
    }

    loadItems() {
        // console.log('loadItems..')

        var self = this;
        var url = api.baseUrl;

        if (this.state.nextHref) {
            url = this.state.nextHref;
        }

        qwest
            .get(
                api.baseUrl,
                {
                    page: this.state.pageIndex
                },
                {
                    cache: true
                }
            )
            .then(function(xhr, resp) {
                if (resp) {
                    var portfolios = self.state.portfolios;
                    resp.map(portfolio => {
                        var dataArr = {};
                        dataArr.id = portfolio.id;
                        dataArr.title = portfolio.title;
                        dataArr.image = portfolio.image;
                        dataArr.company = portfolio.acf.company;
                        dataArr.logo = portfolio.acf.logo;
                        dataArr.order = portfolio.acf.order;
                        dataArr.gallery = portfolio.acf.gallery;
                        dataArr.content = portfolio.acf.description;
                        dataArr.video = portfolio.acf.video_gallery;

                        portfolios.push(dataArr);
                    });

                    self.setState({
                        pageIndex: self.state.pageIndex + 1
                    });

                    if (resp.length < 10) {
                        self.setState({ hasMoreItems: false });
                    }
                }
            })
            .catch(function(error) {
                self.setState({ hasMoreItems: false });
            });
    }

    onCloseModal = () => {
        this.setState({ isOpen: false });
    };

    componentDidMount() {
        // console.log('componentDidMount..')
        this.loadItems();
        this.targetElement = this.portal.current;
    }

    handleOpenClick(e) {
        e.preventDefault();
        var i = e.target.getAttribute("data-index");

        // this.refs.portal.toggleModal(i);
        this.portal.current.toggleModal(i);
        const navigation = document.querySelector(".navigation");
        navigation.style.display = "none";

        disableBodyScroll(document.querySelector(".portal"));
        document.querySelector(".portal").scrollTo(0, 0);
        document.querySelector('html').style.overflowY = "hidden";
    }

    render() {
        // console.log('render..')

        var elements = [];
        this.state.portfolios.map((portfolio, i) => {
            // console.log(this.state.portfolios);

            if(portfolio.order != "") {
            elements.push(
                <div
                    className="thumbnail"
                    style={{
                        backgroundImage: "url(" + portfolio.logo + ")",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <img
                        src={portfolio.image}
                        className="openPortal"
                        key={i}
                        data-index={i}
                        id={portfolio.id}
                        onClick={this.handleOpenClick}
                        alt=""
                    />
                </div>
            );
            }
        });

        return (
            <>
                <div className="grid-work">
                    {elements}
                    <div className="thumbnail">
                        <button>
                            <Link
                                to="/ExceptionalWork"
                                style={{ whiteSpace: "pre-line" }}
                            >
                                {`+ SEE 
                                            MORE`}
                            </Link>
                        </button>
                    </div>
                </div>

                <Portal
                    id="xxx"
                    isOpen={this.state.isOpen}
                    closeModal={this.onCloseModal}
                    portfolios={this.state.portfolios}
                    // ref="portal"
                    ref={this.portal}
                />
            </>
        );
    }
}
