import React, { Component } from "react";
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "../js/debug.addIndicators.js";
import "../scss/Client.scss";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
const $ = window.$;

class Client extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
        this.tl = new TimelineMax({ paused: false });
        this.tl2 = new TimelineMax({ paused: false });
        this.tl3 = new TimelineMax({ paused: false });
    }

    componentDidMount() {
        var screen = $(window).width();
        if (screen > 576) {
            // console.log(">576");
            //  Desktop
            const g1_1 = document.querySelector(
                ".section-client .container-desktop #g-1"
            );
            const g2_1 = document.querySelector(
                ".section-client .container-desktop #g-2"
            );
            const imgInter = $(".imgRandom").toArray();
            imgInter.sort(() => {
                return 0.7 - Math.random();
            });

            const imgLocal = $(".imgLocal").toArray();
            imgLocal.sort(() => {
                return 0.7 - Math.random();
            });

            new ScrollMagic.Scene({
                triggerElement: ".section-client",
                triggerHook: 0.3,
                reverse: false
            })
                .setTween(
                    this.tl
                        .from(g1_1, 0.4, {
                            opacity: 0,
                            xPercent: 100,
                            ease: Expo.easeOut
                        })
                        .from(
                            g2_1,
                            0.4,
                            {
                                opacity: 0,
                                xPercent: -100,
                                ease: Expo.easeOut
                            },
                            "-=0.4"
                        )
                        .from(
                            ".interText",
                            0.4,
                            {
                                y: 20,
                                opacity: 0,
                                ease: Expo.easeOut
                            },
                            "-=0.2"
                        )
                        .from(
                            ".imgRandom",
                            0.8,
                            {
                                opacity: 0,
                                ease: Expo.easeInOut
                            },
                            0.04,
                            "-=0.4"
                        )
                        .from(
                            ".localTextx",
                            0.6,
                            {
                                y: 20,
                                opacity: 0,
                                ease: Expo.easeOut
                            },
                            "-=0.2"
                        )
                        .from(
                            ".imgLocal",
                            0.8,
                            {
                                opacity: 0,
                                ease: Expo.easeInOut
                            },
                            0.04,
                            "-=0.4"
                        )
                )
                // .addIndicators({name: "desktop"})
                .addTo(this.controller);
        } else {
            // console.log("<576");
            // Mobile
            const g1 = document.querySelector(
                ".section-client .container-mobile .g1-mobile"
            );
            const g2 = document.querySelector(
                ".section-client .container-mobile .g2-mobile"
            );
            const slide = document.querySelectorAll(
                ".section-client .slide-upx"
            );
            const slideInter = document.querySelectorAll(
                ".section-client .slide-up-inter"
            );

            new ScrollMagic.Scene({
                triggerElement: ".section-client",
                triggerHook: 0.5,
                reverse: false
            })
                .setTween(
                    this.tl
                        .from(g1, 0.6, {
                            opacity: 0,
                            xPercent: 100,
                            ease: Expo.easeOut
                        })
                        .from(
                            g2,
                            0.6,
                            {
                                opacity: 0,
                                xPercent: -100,
                                ease: Expo.easeOut
                            },
                            "-=0.6"
                        )
                )
                // .addIndicators({ name: "mobile" })
                .addTo(this.controller);

            new ScrollMagic.Scene({
                triggerElement: ".interText-mobile",
                triggerHook: 0.6,
                reverse: false
            })
                .setTween(
                    this.tl2
                        .from(
                            ".interText-mobile",
                            0.6,
                            {
                                y: 20,
                                opacity: 0,
                                ease: Expo.easeOut
                            },
                            "-=0.2"
                        )
                        .staggerFrom(
                            slideInter,
                            0.4,
                            {
                                opacity: 0,
                                yPercent: 100,
                                ease: Expo.easeOut
                            },
                            0.2,
                            "-=0.4"
                        )
                )
                // .addIndicators({ name: "mobile2" })
                .addTo(this.controller);

            new ScrollMagic.Scene({
                triggerElement: ".localText-mobile",
                triggerHook: 0.6,
                reverse: false
            })
                .setTween(
                    this.tl3

                        .from(
                            ".localText-mobile",
                            0.6,
                            {
                                y: 20,
                                opacity: 0,
                                ease: Expo.easeOut
                            },
                            "-=0.2"
                        )
                        .staggerFrom(
                            slide,
                            0.4,
                            {
                                opacity: 0,
                                yPercent: 100,
                                ease: Expo.easeOut
                            },
                            0.2,
                            "-=0.4"
                        )
                )
                // .addIndicators({ name: "mobile3" })
                .addTo(this.controller);
        }

        //   this.triggerAnim(0.5, ".interText", 0.8);
        //   this.triggerAnim(0.5, ".grid-inter .row-1", 1.2);
        //   this.triggerAnim(0.5, ".grid-inter .row-2", 1.4);
        //   this.triggerAnim(0.5, ".localText", 1.8);
        //   this.triggerAnim(0.5, ".grid-local .row-1", 2.2);
        //   this.triggerAnim(0.5, ".grid-local .row-2", 2.4);
        //   this.triggerAnim(0.5, ".grid-local .row-3", 2.6);

        // ---------------------------------------------------------------- //
    }

    // triggerAnim = (h, el, d) => {
    //   new ScrollMagic.Scene({
    //     triggerElement: "#client",
    //     triggerHook: h,
    //     reverse: false
    //   })
    //     .setTween(
    //       TweenMax.to(
    //         el,
    //         0.8,
    //         {
    //           opacity: 1,
    //           delay: d,
    //           ease: Expo.easeOut
    //         }
    //         // "-=0.8"
    //       )
    //     )
    //     // .addIndicators({ name: "xxxx" })
    //     .addTo(this.controller);
    // };

    // // Desktop
    // triggerSlideUp = () => {
    //   const g1 = document.querySelector(".section-client #g-1");
    //   const g2 = document.querySelector(".section-client #g-2");
    //   const imgInter = $('.imgRandom').toArray();
    //   imgInter.sort(()=> {
    //     return 0.5-Math.random()
    //   })

    //   const imgLocal = $('.imgLocal').toArray();
    //   imgLocal.sort(()=> {
    //     return 0.5-Math.random()
    //   })

    //   new ScrollMagic.Scene({
    //     triggerElement: ".section-client",
    //     triggerHook: 0.5,
    //     reverse: false
    //   })
    //     .setTween(
    //       this.tl
    //         .from(g1, 0.6, {
    //           opacity: 0,
    //           xPercent: 100,
    //           ease: Expo.easeOut
    //         })
    //         .from(g2, 0.6, {
    //           opacity: 0,
    //           xPercent: -100,
    //           ease: Expo.easeOut
    //         }, "-=0.6")
    //         .from('.interText', 0.6, {
    //           y: 20,
    //           opacity: 0,
    //           ease: Expo.easeOut
    //         }, "-=0.2")
    //         .staggerFrom(imgInter, 0.6,{
    //           autoAlpha: 0,
    //           ease: Expo.easeOut
    //         },0.2, "-=0.6")
    //         .from('.localTextx', 0.6, {
    //           y: 20,
    //           opacity: 0,
    //           ease: Expo.easeOut
    //         }, "-=0.2")
    //         .staggerFrom(imgLocal, 0.25,{
    //           autoAlpha: 0,
    //           ease: Expo.easeOut
    //         },0.2, "-=0.6")

    //     )
    //     // .addIndicators({name: "desktop"})
    //     .addTo(this.controller);

    // };

    render() {
        return (
            <section className="section-client" id="client">
                <div className="container">
                    <div className="wrapper">
                        <div className="container-desktop">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="697.1768"
                                height="196.1406"
                                viewBox="0 0 697.1768 196.1406"
                            >
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="defult">
                                        <g id="g-1">
                                            <path
                                                className="ch-o"
                                                d="M233.415,121.3838c-31.7031,0-49.84-22.8486-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8394,22.5635,49.8394,49.84S265.2607,121.3838,233.415,121.3838Zm0-98.1084c-20.85,0-27.562,20.4219-27.562,48.126,0,27.4189,6.7119,48.1259,27.562,48.1259s27.5616-20.4218,27.5616-48.1259C260.9766,43.9824,254.2646,23.2754,233.415,23.2754Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                className="ch-u"
                                                d="M317.6709,87.11V46.6953c0-19.4209-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754h42.128v1.7139l-2.999.1425c-6.4263.2862-7.8545,1.9991-7.8545,21.5635V86.3965c0,19.1357,9.7109,30.7031,29.561,30.7031,18.5649,0,29.1328-12.4238,29.1328-31.2744V58.5488c0-16.4228-6.1406-32.9883-15.8516-33.417l-2.999-.1425V23.2754h32.9883v1.7139l-1.5708.1425c-6.8545.5713-10.4248,11.4248-10.4248,33.417V85.8252c0,20.9922-12.4243,35.416-38.8437,35.416C334.522,121.2412,317.6709,108.96,317.6709,87.11Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                className="ch-r"
                                                d="M503.89,119.5273c-1.142-2.7128-2.8559-6.2832-5.7119-11.9961-2.5708-5.4267-6.7119-14.1376-9.14-18.5644-7.7119-13.9951-10.9961-16.5654-19.1362-16.5654H464.19v23.706c0,19.5645,1.4282,21.2774,7.8544,21.5635l2.9991.1426v1.7138H432.915v-1.7138l2.9986-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754H485.04c20.5639,0,34.2734,7.8545,34.2734,23.9912,0,11.4248-8.14,19.4219-20.564,22.707a72.37,72.37,0,0,1-19.9931,2.4278c17.5654,2.7129,26.2763,7.5683,32.7026,18.5644l6.7119,11.5674c6.4263,11.1387,10.14,14.9951,14.4238,15.1377l2.57.1426v1.7138ZM482.0405,25.1318H471.0444c-5.998,0-6.8549,2.2852-6.8549,21.5635V70.6875h15.4233c13.1382,0,17.5649-9.2822,17.5649-23.1348C497.1777,32.5576,491.6084,25.1318,482.0405,25.1318Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                        </g>
                                        <g id="g-2">
                                            <path
                                                d="M86.1128,217.56c-5.1411-4.8555-8.5684-6.2832-11.4248-6.2832-1.999,0-3.7129.7138-6.2832,2.1416a29.4711,29.4711,0,0,1-15.709,4.1416c-29.561,0-46.8408-23.1348-46.8408-49.84,0-26.5615,16.8515-50.125,45.9839-50.125a31.1951,31.1951,0,0,1,15.709,3.9991c2.4277,1.2851,3.9985,2.1416,5.8549,2.1416,2.7134,0,5.4268-1.5713,9.8535-6.1407h1.1426v31.5606H82.9707L81.4,144.3c-4.2842-13.2812-14.709-24.8486-28.5615-24.8486-18.85,0-24.7056,20.707-24.7056,48.1259s6.1406,48.126,25.5625,48.126c14.7095,0,24.9912-12.9951,30.418-29.1328l1.7138-4.8555h1.4278V217.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M116.8154,215.7031v-1.7138l2.9986-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9986-.1426v-1.7138h43.1275v1.7138l-2.9991.1426c-7.4257.2862-8.854,1.999-8.854,21.5635v49.4121c0,19.2783.857,21.5635,6.712,21.5635h7.2832c16.5654,0,22.8491-7.5684,30.7036-30.418l1.7138-4.8555h1.2852v37.13Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M221.4917,215.7031v-1.7138l2.9985-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9985-.1426v-1.7138H263.62v1.7138l-2.999.1426c-6.4263.2862-7.8545,1.999-7.8545,21.5635v49.4121c0,19.5645,1.4282,21.2773,7.8545,21.5635l2.999.1426v1.7138Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M286.7534,215.7031v-1.7138l2.9986-.1426c6.2836-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4283-21.2773-7.7119-21.5635l-2.9986-.1426v-1.7138h75.4019V148.584H360.87l-1.7139-4.8555c-5.4267-15.709-15.1376-22.4209-29.2753-22.4209h-4.9981c-5.998,0-6.855,2.2852-6.855,21.5635v22.5644h6.5694c7.2832,0,13.5664-4.1416,16.7085-14.4238l1-3.1416H343.59v36.8438h-1.2851l-1-3.1416c-3.1421-10.2823-9.4253-14.4239-16.7085-14.4239h-6.5694v25.1348c0,19.2783.857,21.5635,6.7119,21.5635h7.9971c15.4234,0,22.7066-7.7119,29.2759-26.7051l1.7134-4.8555h1.2851v33.417Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M475.97,216.56l-67.833-86.8262v49.5537c0,17.1367,6.4263,34.1309,15.8516,34.56l2.999.1426v1.7138H393.9985v-1.7138l1.5708-.1426c6.4268-.5713,10.4253-11.2822,10.4253-34.56V141.5859c0-18.4218-2.8564-19.9921-9.4253-20.2783l-2.5708-.1426v-1.7138h32.2744l57.98,75.26V154.7246c0-16.4228-6.1411-32.9883-15.8515-33.417l-2.9991-.1426v-1.7138h32.9883v1.7138l-1.5708.1426c-6.855.5713-10.4248,11.4248-10.4248,33.417V216.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M542.9448,215.7031v-1.7138l2.999-.1426c6.4263-.2862,7.8545-1.999,7.8545-21.5635V142.5859c0-19.2793-.8569-21.2783-6.5693-21.2783h-2.4277c-8.4253,0-16.5655,7.8545-22.2779,25.2774l-1.5708,4.8554h-1.4282V119.4512h88.9688V151.44H607.208l-1.7139-4.8554c-5.7119-17.4229-13.852-25.2774-22.2778-25.2774h-2.4277c-5.5694,0-6.4263,1.999-6.4263,21.2783v49.6973c0,19.5645,1.4282,21.2773,7.7119,21.5635l2.9986.1426v1.7138Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M667.4727,217.56a34.6755,34.6755,0,0,1-18.2793-4.8555c-2.9991-1.5713-4.7129-2.1416-6.4263-2.1416-3.2847,0-6.7119,2.4277-11.2817,6.9971h-1.1426V182.8574h1.2851l1.7139,5.1416c4.57,13.9951,15.7085,27.5615,34.1309,27.5615,10.9961,0,19.1362-4.7128,19.1362-15.8515,0-25.8486-52.2676-21.707-52.2676-54.8379,0-16.709,14.4233-27.2764,30.9893-27.2764a30.9337,30.9337,0,0,1,15.4228,4.1416c2.4278,1.2852,3.856,1.9991,5.8555,1.9991,2.7134,0,5.4263-1.5713,9.7109-6.1407h1.2852v30.418h-1.4282l-1.5708-4.8555c-4.4273-13.5664-15.9947-23.706-28.99-23.706-9.854,0-15.4233,5.1406-15.4233,13.2812,0,26.7051,52.8388,21.7061,52.8388,56.5518C703.0317,206.7061,687.18,217.56,667.4727,217.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div className="hidden">
                                <h3 className="interText">
                                    INTERNATIONAL BRANDS
                                </h3>
                            </div>
                            <div className="grid-inter">
                                <div className="row-1 int1">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_1.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-1 int2">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_2.svg"
                                        alt=""
                                        style={{height:"60px"}}

                                    />
                                </div>
                                <div className="row-1 int3">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_3.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-1 int4">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_4.svg"
                                        alt=""
                                        style={{height:"65px"}}
                                    />
                                </div>

                                <div className="row-2 int5">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_5.svg"
                                        alt=""
                                        style={{height:"70px"}}
                                    />
                                </div>
                                <div className="row-2 int6">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_6.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int7">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_7.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int8">
                                    <img
                                        className="imgRandom"
                                        src="/assets/inter_8.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int9">
                                    <img
                                        className="imgRandom kwg"
                                        src="/assets/inter_9.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="hidden">
                                <h3 className="localTextx">LOCAL BRANDS</h3>
                            </div>
                            <div className="grid-local">
                                <div className="row-1 int1">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_1.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-1 int2">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_2.svg"
                                        alt=""
                                        style={{height:"80px", maxWidth:"110px"}}
                                    />
                                </div>
                                <div className="row-1 int3">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_3.svg"
                                        alt=""
                                        style={{height:"55px"}}
                                    />
                                </div>
                                <div className="row-1 int4">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_4.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-1 int5">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_5.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-1 int6">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_6.svg"
                                        alt=""
                                        style={{height:"55px"}}
                                    />
                                </div>
                                <div className="row-1 int7">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_7.svg"
                                        alt=""
                                        style={{height:"50px"}}
                                    />
                                </div>
                                <div className="row-1 int8">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_8.svg"
                                        alt=""
                                    />
                                </div>

                                <div className="row-2 int9">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_9.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int10">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_10.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int11">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_11.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int12">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_12.svg"
                                        alt=""
                                        style={{height:"50px", maxWidth:"110px"}}
                                    />
                                </div>
                                <div className="row-2 int13">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_13.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-2 int14">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_14.svg"
                                        alt=""
                                        style={{height:"50px", maxWidth:"120px"}}
                                    />
                                </div>
                                <div className="row-2 int15">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_15.svg"
                                        alt=""
                                    />
                                </div>

                                <div className="row-3 int16">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_16.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-3 int17">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_17.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-3 int18">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_18.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="row-3 int19">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_19.svg"
                                        alt=""
                                        style={{height:"50px", maxWidth:"120px"}}
                                    />
                                </div>
                                <div className="row-3 int20">
                                    <img
                                        className="imgLocal"
                                        src="/assets/local_20.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-mobile">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="697.1768"
                                height="196.1406"
                                viewBox="0 0 697.1768 196.1406"
                            >
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="defult">
                                        <g className="g1-mobile">
                                            <path
                                                className="ch-o"
                                                d="M233.415,121.3838c-31.7031,0-49.84-22.8486-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8394,22.5635,49.8394,49.84S265.2607,121.3838,233.415,121.3838Zm0-98.1084c-20.85,0-27.562,20.4219-27.562,48.126,0,27.4189,6.7119,48.1259,27.562,48.1259s27.5616-20.4218,27.5616-48.1259C260.9766,43.9824,254.2646,23.2754,233.415,23.2754Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                className="ch-u"
                                                d="M317.6709,87.11V46.6953c0-19.4209-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754h42.128v1.7139l-2.999.1425c-6.4263.2862-7.8545,1.9991-7.8545,21.5635V86.3965c0,19.1357,9.7109,30.7031,29.561,30.7031,18.5649,0,29.1328-12.4238,29.1328-31.2744V58.5488c0-16.4228-6.1406-32.9883-15.8516-33.417l-2.999-.1425V23.2754h32.9883v1.7139l-1.5708.1425c-6.8545.5713-10.4248,11.4248-10.4248,33.417V85.8252c0,20.9922-12.4243,35.416-38.8437,35.416C334.522,121.2412,317.6709,108.96,317.6709,87.11Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                className="ch-r"
                                                d="M503.89,119.5273c-1.142-2.7128-2.8559-6.2832-5.7119-11.9961-2.5708-5.4267-6.7119-14.1376-9.14-18.5644-7.7119-13.9951-10.9961-16.5654-19.1362-16.5654H464.19v23.706c0,19.5645,1.4282,21.2774,7.8544,21.5635l2.9991.1426v1.7138H432.915v-1.7138l2.9986-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754H485.04c20.5639,0,34.2734,7.8545,34.2734,23.9912,0,11.4248-8.14,19.4219-20.564,22.707a72.37,72.37,0,0,1-19.9931,2.4278c17.5654,2.7129,26.2763,7.5683,32.7026,18.5644l6.7119,11.5674c6.4263,11.1387,10.14,14.9951,14.4238,15.1377l2.57.1426v1.7138ZM482.0405,25.1318H471.0444c-5.998,0-6.8549,2.2852-6.8549,21.5635V70.6875h15.4233c13.1382,0,17.5649-9.2822,17.5649-23.1348C497.1777,32.5576,491.6084,25.1318,482.0405,25.1318Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                        </g>
                                        <g className="g2-mobile">
                                            <path
                                                d="M86.1128,217.56c-5.1411-4.8555-8.5684-6.2832-11.4248-6.2832-1.999,0-3.7129.7138-6.2832,2.1416a29.4711,29.4711,0,0,1-15.709,4.1416c-29.561,0-46.8408-23.1348-46.8408-49.84,0-26.5615,16.8515-50.125,45.9839-50.125a31.1951,31.1951,0,0,1,15.709,3.9991c2.4277,1.2851,3.9985,2.1416,5.8549,2.1416,2.7134,0,5.4268-1.5713,9.8535-6.1407h1.1426v31.5606H82.9707L81.4,144.3c-4.2842-13.2812-14.709-24.8486-28.5615-24.8486-18.85,0-24.7056,20.707-24.7056,48.1259s6.1406,48.126,25.5625,48.126c14.7095,0,24.9912-12.9951,30.418-29.1328l1.7138-4.8555h1.4278V217.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M116.8154,215.7031v-1.7138l2.9986-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9986-.1426v-1.7138h43.1275v1.7138l-2.9991.1426c-7.4257.2862-8.854,1.999-8.854,21.5635v49.4121c0,19.2783.857,21.5635,6.712,21.5635h7.2832c16.5654,0,22.8491-7.5684,30.7036-30.418l1.7138-4.8555h1.2852v37.13Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M221.4917,215.7031v-1.7138l2.9985-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9985-.1426v-1.7138H263.62v1.7138l-2.999.1426c-6.4263.2862-7.8545,1.999-7.8545,21.5635v49.4121c0,19.5645,1.4282,21.2773,7.8545,21.5635l2.999.1426v1.7138Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M286.7534,215.7031v-1.7138l2.9986-.1426c6.2836-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4283-21.2773-7.7119-21.5635l-2.9986-.1426v-1.7138h75.4019V148.584H360.87l-1.7139-4.8555c-5.4267-15.709-15.1376-22.4209-29.2753-22.4209h-4.9981c-5.998,0-6.855,2.2852-6.855,21.5635v22.5644h6.5694c7.2832,0,13.5664-4.1416,16.7085-14.4238l1-3.1416H343.59v36.8438h-1.2851l-1-3.1416c-3.1421-10.2823-9.4253-14.4239-16.7085-14.4239h-6.5694v25.1348c0,19.2783.857,21.5635,6.7119,21.5635h7.9971c15.4234,0,22.7066-7.7119,29.2759-26.7051l1.7134-4.8555h1.2851v33.417Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M475.97,216.56l-67.833-86.8262v49.5537c0,17.1367,6.4263,34.1309,15.8516,34.56l2.999.1426v1.7138H393.9985v-1.7138l1.5708-.1426c6.4268-.5713,10.4253-11.2822,10.4253-34.56V141.5859c0-18.4218-2.8564-19.9921-9.4253-20.2783l-2.5708-.1426v-1.7138h32.2744l57.98,75.26V154.7246c0-16.4228-6.1411-32.9883-15.8515-33.417l-2.9991-.1426v-1.7138h32.9883v1.7138l-1.5708.1426c-6.855.5713-10.4248,11.4248-10.4248,33.417V216.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M542.9448,215.7031v-1.7138l2.999-.1426c6.4263-.2862,7.8545-1.999,7.8545-21.5635V142.5859c0-19.2793-.8569-21.2783-6.5693-21.2783h-2.4277c-8.4253,0-16.5655,7.8545-22.2779,25.2774l-1.5708,4.8554h-1.4282V119.4512h88.9688V151.44H607.208l-1.7139-4.8554c-5.7119-17.4229-13.852-25.2774-22.2778-25.2774h-2.4277c-5.5694,0-6.4263,1.999-6.4263,21.2783v49.6973c0,19.5645,1.4282,21.2773,7.7119,21.5635l2.9986.1426v1.7138Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                            <path
                                                d="M667.4727,217.56a34.6755,34.6755,0,0,1-18.2793-4.8555c-2.9991-1.5713-4.7129-2.1416-6.4263-2.1416-3.2847,0-6.7119,2.4277-11.2817,6.9971h-1.1426V182.8574h1.2851l1.7139,5.1416c4.57,13.9951,15.7085,27.5615,34.1309,27.5615,10.9961,0,19.1362-4.7128,19.1362-15.8515,0-25.8486-52.2676-21.707-52.2676-54.8379,0-16.709,14.4233-27.2764,30.9893-27.2764a30.9337,30.9337,0,0,1,15.4228,4.1416c2.4278,1.2852,3.856,1.9991,5.8555,1.9991,2.7134,0,5.4263-1.5713,9.7109-6.1407h1.2852v30.418h-1.4282l-1.5708-4.8555c-4.4273-13.5664-15.9947-23.706-28.99-23.706-9.854,0-15.4233,5.1406-15.4233,13.2812,0,26.7051,52.8388,21.7061,52.8388,56.5518C703.0317,206.7061,687.18,217.56,667.4727,217.56Z"
                                                transform="translate(-5.855 -21.4189)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div className="hidden">
                                <h3 className="interText-mobile">
                                    INTERNATIONAL BRANDS
                                </h3>
                            </div>
                            <div className="grid-inter-mobile slide-up-inter">
                                <div className="container-1">
                                    <div className="animate">
                                        <img src="/assets/inter_1.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_2.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_3.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_4.svg" alt="" />
                                    </div>

                                    <div className="animate">
                                        <img src="/assets/inter_1.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_2.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_3.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_4.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-inter-mobile slide-up-inter">
                                <div className="container-2">
                                    <div className="animate">
                                        <img src="/assets/inter_5.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_6.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_7.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_8.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_9.svg" alt="" />
                                    </div>

                                    <div className="animate">
                                        <img src="/assets/inter_5.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_6.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_7.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_8.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/inter_9.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden">
                                <h3 className="localText-mobile">
                                    LOCAL BRANDS
                                </h3>
                            </div>
                            <div className="grid-local-mobile slide-upx">
                                <div className="container-1">
                                    <div className="animate">
                                        <img src="/assets/local_1.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_2.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_3.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_4.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_5.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_6.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_7.svg" alt="" />
                                    </div>

                                    <div className="animate">
                                        <img src="/assets/local_1.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_2.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_3.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_4.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_5.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_6.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_7.svg" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid-local-mobile slide-upx">
                                <div className="container-2">
                                    <div className="animate">
                                        <img src="/assets/local_8.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_9.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_10.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_11.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_12.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_13.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_14.svg"
                                            alt=""
                                        />
                                    </div>

                                    <div className="animate">
                                        <img src="/assets/local_8.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img src="/assets/local_9.svg" alt="" />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_10.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_11.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_12.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_13.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_14.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="grid-local-mobile slide-upx">
                                <div className="container-3">
                                    <div className="animate">
                                        <img
                                            src="/assets/local_15.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_16.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_17.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_18.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_19.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_20.svg"
                                            alt=""
                                        />
                                    </div>

                                    <div className="animate">
                                        <img
                                            src="/assets/local_15.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_16.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_17.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_18.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_19.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="animate">
                                        <img
                                            src="/assets/local_20.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Client;
