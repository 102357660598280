import React from "react";
import "../scss/WhyUs.scss";
import * as ScrollMagic from "scrollmagic";
import "../js/debug.addIndicators.js";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

export default class WhyUs extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
        this.tl = new TimelineMax({ paused: false });
    }

    componentDidMount() {
        new ScrollMagic.Scene({
            triggerElement: ".section-whyus",
            triggerHook: 0.5,
            reverse: false
        })
            .setTween(
                this.tl.staggerFrom(".section-whyus .container >div", 0.6, {
                    opacity: 0,
                    y: 20,
                    ease: Expo.easeInOut
                }, 0.2)
                .to(".section-whyus .container hr", 0.6, {
                    width: '30%',
                    opacity: 1,
                    ease: Expo.easeInOut,
                    delay: 1
                }, 0.2)
            )
            // .addIndicators({ name: "why us..." })
            // .setPin('.section-whyus')
            .addTo(this.controller);
            
    }
    render() {
        return (
            <section className="section-whyus" id="whyus">
                <div className="container">
                    <div className="hidden">
                        <h1 className="first-step">WHY US</h1>
                    </div>
                    <div className="hidden">
                        <h2 className="first-step">WE ARE BOUTIQUE TEAM</h2>
                    </div>
                    <hr />
                    <div className="hidden">
                        <h2 className="second-step">
                            WE ARE FAST AND NIMBLE,
                            <br />
                            TURNING IDEAS INTO REALITY IN REAL TIME.
                        </h2>
                    </div>
                    <hr />
                    <div className="hidden">
                        <h2 className="third-step">
                            WITH OUR LONG-TIME PARTNERS AND TRUSTED SUPPLIERS
                        </h2>
                    </div>
                    <div className="hidden">
                        <p className="third-step">
                            WITH OUR LONG-TIME PARTNERS AND TRUSTED SUPPLIERS,
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}
