import React from "react";
import ReactDOM from "react-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const initialState = {
    showMap: false,
    showLogo: false,
    showGallery: false,
    showWork: false,
    showBackground: false
}

function reducer(state = initialState, action) {
    // console.log('reducer', state, action);
    switch(action.type) {
        case 'SHOW_MAP':
            return {...state, showMap: action.payload};

        case 'SHOW_LOGO': 
            return {...state, showLogo: action.payload};
        
        case 'SHOW_GALLERY': 
            return {...state, showGallery: action.payload};
        
        case 'SHOW_WORK': 
            return {...state, showWork: action.payload};

        case 'SHOW_BACKGROUND': 
            return {...state, showBackground: action.payload};

        default:
            return state;
    }
}

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App /> 
    </Provider>, 
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
