import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux';
import "../scss/Jobs.scss";

class Jobs extends React.Component {

  componentDidMount() {
    this.props.dispatch({ type: 'SHOW_MAP', payload: false });
    this.props.dispatch({ type: 'SHOW_LOGO', payload: true });

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="section-jobs">
        <div className="container">
          <div className="register-form">
            <div className="text-header">
              <img src="/assets/work_with_us.svg" />
            </div>
            <div className="wrapper">
              <div className="box-left">
                <div className="container-wrapper">
                  <div className="container-header">
                    <div className="wrapper-header">
                      <img src="/assets/plus_gold.svg" alt="" />
                      <h4>WE ARE HIRING</h4>
                    </div>
                    <ul>
                      <li>
                        <h5>GRAPHIC DESIGNER</h5>
                        <p>2 positions</p>
                      </li>
                      <li>
                        <h5>ACCOUNT EXECUTIVE</h5>
                        <p>1 position</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="box-right">
                <h1>APPLICATION FORM</h1>

                <div className="wrapper">
                  <div className="row">
                    <div className="col-2">
                      <label>
                        <h5>FIRST NAME</h5>
                      </label>
                      <input type="text" />
                      <span />
                    </div>

                    <div className="col-2">
                      <label>
                        <h5>LAST NAME</h5>
                      </label>
                      <input type="text" />
                      <span />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-2">
                      <label>
                        <h5>E-MAIL</h5>
                      </label>
                      <input type="text" />
                      <span />
                    </div>

                    <div className="col-2">
                      <label>
                        <h5>PHONE</h5>
                      </label>
                      <input type="text" />
                      <span />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <label>
                        <h5>POSITION</h5>
                      </label>
                      <div className="element">
                        <div className="col-2">
                        <label className="container-label">Graphic Designer
                          <input type="checkbox"/>
                          <span className="checkmark"></span>
                        </label>
                        </div>

                        <div className="col-2">
                        <label className="container-label">Account Executive
                          <input type="checkbox"/>
                          <span className="checkmark"></span>
                        </label>
                        </div>
                      </div>
                      <span />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <label>
                        <span>RESUME</span>
                        <span> (JPG, PNG, PDF MAXIMUM 24 MB*)</span>
                      </label>
                    </div>
                    <div className="col-3">
                      <button className="btn-upload">UPLOAD</button>
                      <div className="wrapper">
                        <span>or</span>
                      </div>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <label>
                        <span>PORTFOLIO</span>
                        <span> (PDF MAXIMUM 24 MB*)</span>
                      </label>
                    </div>
                    <div className="col-3">
                      <button className="btn-upload">UPLOAD</button>
                      <div className="wrapper">
                        <span>or</span>
                      </div>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <button className="btn-submit">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      showMap: state.showMap,
      showLogo: state.showLogo,
  }
}

export default connect(mapStateToProps)(Jobs);