import React from "react";
import "../scss/Service.scss";
import { triggerX, triggerY, triggerSlideX } from "../js/MainAnim.js";
import * as ScrollMagic from "scrollmagic";
import "../js/debug.addIndicators.js";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
const $ = window.$;

export default class Service extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();

        this.tl = new TimelineMax({});
    }

    // componentDidUpdate() {
    //     const section = document.querySelector(".section-service");
        
    //     const positionSection = section.offsetTop; //current
    //     const duration = section.offsetHeight;
    //     const pin = document.querySelector("#trigger-service");

    //     console.log(positionSection, section.offsetHeight);
    //     window.onscroll = () => {
    //         // console.log(window.pageYOffset)

    //         //   // console.log(window.scrollY)
    //         if (window.scrollY > section.offsetTop-1) {
    //             // pin.scrollTop
    //             console.log("arrived section service ");


    //             const elMove =  window.pageYOffset - section.offsetTop
    //             // elMove = parseFloat(elMove.toFixed(2));

    //             pin.style.transform = "translateY(" + elMove + "px)";
    //             // trigger.style.transform = "translateY(-500px)";

    //         }
    //     };
    // }

    componentDidMount() {
        // Desktop
        // new ScrollMagic.Scene({
        //   triggerElement: '#trigger-service',
        //   triggerHook: 0,
        //   duration: '100%'
        // })
        // .addIndicators({ name: "pin service" })
        // .setPin('.section-service .container-desktop')
        // .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: "#trigger-service",
            triggerHook: 0,
            reverse: false
        })
            .setTween(
                ".wrapper-line",
                0.8,
                {
                    transform: "rotate(0)",
                    ease: Expo.easeOut
                },
                "+=0.8"
            )
            .addTo(this.controller);

        triggerY("#h-1", 0.3);
        triggerY("#branding", 0.3);
        triggerY(".branding", 0.3, 20);
        triggerX("#h-2", 0.6);
        triggerX("#communication", 0.6);
        triggerX(".communication", 0.6, -20);
        triggerY("#h-3", 0.9);
        triggerY("#online", 0.9);
        triggerY(".online", 0.9, -20);
        triggerX("#h-4", 1.2);
        triggerX("#actication", 1.2);
        triggerX(".actication", 1.2, 20);

        // Mobile
        triggerSlideX(
            ".section-service #slide-left",
            ".section-service",
            100,
            1.5
        );
        this.triggerSlideUp(
            ".section-service .list-wrapper div:nth-child(1)",
            ".g1"
        );
        this.triggerSlideUp(
            ".section-service .list-wrapper div:nth-child(2)",
            ".g2"
        );
        this.triggerSlideUp(
            ".section-service .list-wrapper div:nth-child(3)",
            ".g3"
        );
        this.triggerSlideUp(
            ".section-service .list-wrapper div:nth-child(4)",
            ".g4"
        );

        const grService = document.getElementById("g-service");
        const grOur = document.getElementById("g-our");

        this.triggerSlide(grOur, 100);
        this.triggerSlide(grService, -100, "-=0.6");
    }

    // Mobile
    triggerSlideUp = (trig, el) => {
        new ScrollMagic.Scene({
            triggerElement: trig,
            triggerHook: 0.6,
            reverse: false
        })
            .setTween(
                TweenMax.staggerFrom(
                    el,
                    0.8,
                    {
                        yPercent: 100,
                        opacity: 0,
                        ease: Expo.easeOut
                    },
                    0.1
                )
            )
            // .addIndicators({name: "mobile"})
            .addTo(this.controller);
    };

    triggerSlide = (el, x, d) => {
        new ScrollMagic.Scene({
            triggerElement: ".section-service",
            triggerHook: 0.5,
            reverse: false
        })
            .setTween(
                this.tl.from(
                    el,
                    0.6,
                    {
                        opacity: 0,
                        xPercent: x,
                        ease: Expo.easeOut
                    },
                    d
                )
            )
            // .addIndicators({name: "mobile"})
            .addTo(this.controller);
    };

    render() {
        return (
            <section className="section-service" id="service">
                <div id="trigger-service">
                    <div className="container-desktop">
                        <div className="wrapper">
                            <img
                                className="our-service"
                                src="/assets/our_service-1.svg"
                                alt=""
                            />
                            <div className="our">
                                <div className="hidden">
                                    <img
                                        id="slide-left"
                                        src="/assets/our.svg"
                                    />
                                </div>
                            </div>
                            <div className="grid">
                                <h2 id="h-1">01</h2>
                                <h2 id="h-2">02</h2>
                                <h2 id="h-3">03</h2>
                                <h2 id="h-4">04</h2>

                                <div className="wrapper-line">
                                    <span></span>
                                    <span></span>
                                </div>

                                <h3 id="branding">BRANDING</h3>
                                <h3 id="communication">COMMUNICATION</h3>
                                <h3 id="online">
                                    ONLINE
                                    <br />
                                    COMMUNICATION
                                </h3>
                                <h3 id="actication">ACTICATION</h3>

                                <ul className="branding">
                                    <li>Brand Strategy</li>
                                    <li>Naming</li>
                                    <li>Logo Design</li>
                                    <li>Corporate Identity</li>
                                    <li>Stationery</li>
                                </ul>

                                <ul className="communication">
                                    <li>TVC</li>
                                    <li>VDO / Presentation</li>
                                    <li>Radio Spot</li>
                                    <li>Billboard</li>
                                    <li>Brochure</li>
                                    <li>All Printed Media</li>
                                </ul>

                                <ul className="online">
                                    <li>Website UX / UI</li>
                                    <li>Online Ads</li>
                                    <li>Online VDO</li>
                                </ul>

                                <ul className="actication">
                                    <li>Booth</li>
                                    <li>Exhibition</li>
                                    <li>Constraction</li>
                                    <li>Event</li>
                                    <li>Roadshow</li>
                                </ul>
                            </div>
                            <div className="service">
                                <div className="hidden">
                                    <img
                                        id="slide-left"
                                        src="/assets/service.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-mobile">
                    <div className="wrapper">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="685.4066"
                            height="195.4395"
                            viewBox="0 0 685.4066 195.4395"
                        >
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="g-service">
                                    <path
                                        d="M94.3344,215.002v-1.7139l2.999-.1426c6.2832-.2861,7.7119-1.999,7.7119-21.5635V142.17c0-19.5644-1.4287-21.2773-7.7119-21.5635l-2.999-.1425V118.75h75.4023v29.1328h-1.2852l-1.7138-4.8555c-5.4268-15.7089-15.1377-22.4209-29.2754-22.4209h-4.9981c-5.998,0-6.8545,2.2852-6.8545,21.5635v22.5645h6.5684c7.2832,0,13.5674-4.1416,16.709-14.4239l.999-3.1416h1.2861v36.8438h-1.2861l-.999-3.1416c-3.1416-10.2822-9.4258-14.4238-16.709-14.4238H125.61V191.582c0,19.2784.8565,21.5635,6.712,21.5635h7.997c15.4229,0,22.7061-7.7119,29.2754-26.7051l1.7139-4.8554h1.2851v33.417Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M273.8412,215.002c-1.1426-2.7129-2.8565-6.2832-5.7129-11.9961-2.57-5.4268-6.7119-14.1377-9.14-18.5645-7.7109-13.9951-10.996-16.5654-19.1357-16.5654H234.141v23.706c0,19.5645,1.4277,21.2774,7.8535,21.5635l2.999.1426v1.7139H202.8656v-1.7139l2.999-.1426c6.2832-.2861,7.7119-1.999,7.7119-21.5635V142.17c0-19.5644-1.4287-21.2773-7.7119-21.5635l-2.999-.1425V118.75h52.125c20.5645,0,34.2734,7.8545,34.2734,23.9912,0,11.4248-8.14,19.4219-20.5644,22.707a72.3677,72.3677,0,0,1-19.9932,2.4278c17.5655,2.7129,26.2774,7.5683,32.7031,18.5644l6.712,11.5674c6.4267,11.1387,10.14,14.9951,14.4238,15.1377l2.57.1426v1.7139Zm-21.85-94.3956H240.9955c-5.9981,0-6.8545,2.2852-6.8545,21.5635v23.9922h15.4228c13.1377,0,17.5655-9.2822,17.5655-23.1348C267.1293,128.0322,261.56,120.6064,251.9916,120.6064Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M326.1068,143.5986c-8.4258-20.5644-10.2822-22.2783-15.8515-22.9922l-1.1426-.1425V118.75h42.414v1.7139l-3.7129.1425c-7.5693.2862-6.8554,3.9991.1426,21.9922l21.2783,55.1241,17.4219-45.9844c2.1426-5.9981,3.7139-12.8526,3.7139-18.5645,0-6.8554-2.4277-12.2812-8.9971-12.5674l-2.999-.1425V118.75h31.7031v1.7139l-1.5713.1425c-5.998.4288-9.14,3.57-19.707,31.7032l-24.1348,63.5488h-9.14Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M429.4984,215.002v-1.7139l2.999-.1426c6.2832-.2861,7.712-1.999,7.712-21.5635V142.17c0-19.5644-1.4288-21.2773-7.712-21.5635l-2.999-.1425V118.75h42.1279v1.7139l-2.999.1425c-6.4258.2862-7.8535,1.9991-7.8535,21.5635V191.582c0,19.5645,1.4277,21.2774,7.8535,21.5635l2.999.1426v1.7139Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M576.4457,216.8584c-5.1406-4.8555-8.5684-6.2832-11.4238-6.2832-2,0-3.7139.7139-6.2842,2.1416a29.4688,29.4688,0,0,1-15.709,4.1416c-29.5606,0-46.84-23.1348-46.84-49.84,0-26.5616,16.8506-50.125,45.9834-50.125a31.1965,31.1965,0,0,1,15.709,3.999c2.4278,1.2851,3.9981,2.1416,5.8545,2.1416,2.7139,0,5.4268-1.5713,9.8545-6.1406h1.1426v31.5605h-1.4287l-1.57-4.8555c-4.2842-13.2812-14.71-24.8486-28.5616-24.8486-18.8505,0-24.706,20.707-24.706,48.126s6.1406,48.126,25.5625,48.126c14.709,0,24.9912-12.9952,30.418-29.1329l1.7138-4.8554h1.4278v35.8447Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M607.1478,215.002v-1.7139l2.9991-.1426c6.2841-.2861,7.7119-1.999,7.7119-21.5635V142.17c0-19.5644-1.4278-21.2773-7.7119-21.5635l-2.9991-.1425V118.75H682.55v29.1328H681.265l-1.7139-4.8555c-5.4267-15.7089-15.1376-22.4209-29.2753-22.4209h-4.9981c-5.998,0-6.8545,2.2852-6.8545,21.5635v22.5645h6.5684c7.2832,0,13.5674-4.1416,16.709-14.4239l.999-3.1416h1.2861v36.8438H662.7l-.999-3.1416c-3.1416-10.2822-9.4258-14.4238-16.709-14.4238h-6.5684V191.582c0,19.2784.8565,21.5635,6.7119,21.5635h7.9971c15.4229,0,22.7061-7.7119,29.2754-26.7051l1.7139-4.8554h1.2851v33.417Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M2.9428,129.0834c3.5972-5.7059,12.1094-11.5077,31.2114-11.5077,21.3379,0,32.6223,10.8719,32.6223,23.1921,0,5.2159-1.83,10.3542-8.8285,10.3542-6.112,0-10.05-3.2157-9.3587-9.4331a64.9053,64.9053,0,0,0,1.0357-10.95c0-7.1044-6.9648-10.3728-16.1685-10.3728-13.8025,0-17.7127,7.582-17.7127,20.9533,0,0-.3009,6.8377,2.2636,11.9233,2.8126,5.5756,10.3666,7.6811,22.91,12.0163,8.0594,2.7847,15.6848,4.8561,24.0046,10.9185a19.958,19.958,0,0,1,8.3851,16.6089c0,19.4679-19.8,22.2123-27.6235,22.2123,8.0222-1.6156,11.88-11.39,11.88-20.3021v-2.22c0-9.8208-4.326-18.7361-15.8119-22.8294,0,0-12.2024-3.9413-23.8342-8.4347-4.1212-1.5908-8.2486-3.5878-10.1836-5.1414A19.84,19.84,0,0,1,0,139.9151a20.5653,20.5653,0,0,1,2.9428-10.8317"
                                        transform="translate(0 -21.4189)"
                                    />
                                </g>
                                <g id="g-our">
                                    <path
                                        d="M230.0043,121.3838c-31.7032,0-49.84-22.8486-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8393,22.5635,49.8393,49.84S261.85,121.3838,230.0043,121.3838Zm0-98.1084c-20.85,0-27.562,20.4219-27.562,48.126,0,27.4189,6.7119,48.1259,27.562,48.1259s27.5615-20.4218,27.5615-48.1259C257.5658,43.9824,250.8539,23.2754,230.0043,23.2754Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M314.26,87.11V46.6953c0-19.4209-1.4282-21.2773-7.7119-21.5635l-2.9985-.1425V23.2754h42.1279v1.7139l-2.999.1425c-6.4263.2862-7.8545,1.9991-7.8545,21.5635V86.3965c0,19.1357,9.7109,30.7031,29.561,30.7031,18.565,0,29.1328-12.4238,29.1328-31.2744V58.5488c0-16.4228-6.1406-32.9883-15.8515-33.417l-2.999-.1425V23.2754h32.9882v1.7139l-1.5708.1425c-6.8545.5713-10.4248,11.4248-10.4248,33.417V85.8252c0,20.9922-12.4243,35.416-38.8437,35.416C331.1112,121.2412,314.26,108.96,314.26,87.11Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                    <path
                                        d="M500.4794,119.5273c-1.1426-2.7128-2.8565-6.2832-5.7124-11.9961-2.5708-5.4267-6.7119-14.1376-9.14-18.5644-7.7119-13.9951-10.9961-16.5654-19.1362-16.5654h-5.7124v23.706c0,19.5645,1.4282,21.2774,7.8545,21.5635l2.999.1426v1.7138H429.5043v-1.7138l2.9985-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9985-.1425V23.2754h52.1245c20.564,0,34.2734,7.8545,34.2734,23.9912,0,11.4248-8.14,19.4219-20.5639,22.707a72.37,72.37,0,0,1-19.9932,2.4278c17.5654,2.7129,26.2764,7.5683,32.7026,18.5644l6.7119,11.5674c6.4263,11.1387,10.14,14.9951,14.4239,15.1377l2.57.1426v1.7138ZM478.63,25.1318H467.6337c-5.9981,0-6.855,2.2852-6.855,21.5635V70.6875H476.202c13.1382,0,17.565-9.2822,17.565-23.1348C493.767,32.5576,488.1976,25.1318,478.63,25.1318Z"
                                        transform="translate(0 -21.4189)"
                                    />
                                </g>
                            </g>
                        </svg>
                        <ul className="list-wrapper">
                            <div className="hidden-1">
                                <li className="group g1">
                                    <div className="wrapper">
                                        <h2>01</h2>
                                        <h3>BRANDING</h3>
                                    </div>
                                    <ul>
                                        <li>Brand Strategy</li>
                                        <li>Naming</li>
                                        <li>Logo design</li>
                                        <li>Corporate Identity</li>
                                        <li>Stationery</li>
                                    </ul>
                                </li>
                            </div>
                            <div className="hidden-1">
                                <li className="group g2">
                                    <div className="wrapper">
                                        <h2>02</h2>
                                        <h3>COMMUNICATION</h3>
                                    </div>
                                    <ul>
                                        <li>TVC</li>
                                        <li>VDO / Presentation</li>
                                        <li>Radio Spot</li>
                                        <li>Billboard</li>
                                        <li>Brochure</li>
                                        <li>All Printed Media</li>
                                    </ul>
                                </li>
                            </div>
                            <div className="hidden-1">
                                <li className="group g3">
                                    <div className="wrapper">
                                        <h2>03</h2>
                                        <h3>ONLINE COMMUNICATION</h3>
                                    </div>
                                    <ul>
                                        <li>Website UX / UI</li>
                                        <li>Online Ads</li>
                                        <li>Online VDO</li>
                                    </ul>
                                </li>
                            </div>
                            <div className="hidden-1">
                                <li className="group g4">
                                    <div className="wrapper">
                                        <h2>04</h2>
                                        <h3>ACTIVATION</h3>
                                    </div>
                                    <ul>
                                        <li>Booth</li>
                                        <li>Exhibition</li>
                                        <li>Constraction</li>
                                        <li>Event</li>
                                        <li>Roadshow</li>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}
