import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeAnimate from "./components/HomeAnimate";
import Gallery from "./components/Gallery";
import Service from "./components/Service";
import Cave from "./components/Cave";
import Client from "./components/Client";
import Contact from "./components/Contact";
import Jobs from "./components/Jobs";
import Navigation from "./components/Navigation";
import ExceptionalWork from "./components/ExceptionalWorkApi";
import WhatWeDo from "./components/WhatWeDo";
import WhyUs from "./components/WhyUs";
import styled from "styled-components";
import ReactGA from 'react-ga';


import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { connect } from "react-redux";

function initializeReactGA() {
    ReactGA.initialize('UA-156375770-1');
    ReactGA.pageview('/');
}

const $ = window.$;
const mapStyle = [
    {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: 36
            },
            {
                color: "#000000"
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "on"
            },
            {
                color: "#000000"
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 17
            },
            {
                weight: 1.2
            }
        ]
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 21
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 29
            },
            {
                weight: 0.2
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 18
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 19
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 17
            }
        ]
    }
];
const icon = {
    url: "./assets/pin_map_2.svg",
    scaledSize: { width: 190, height: 70 }
};
const position = { lat: 13.7365291, lng: 100.5761017 };
const MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoIko2M4pzUdCiqSu7UtcVwH-NLqR44yo&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        options={{
            styles: mapStyle
        }}
        defaultZoom={18}
        defaultCenter={{ lat: 13.7365291, lng: 100.5761017 }}
        defaultOptions={{ scrollwheel: false }}
    >
        {props.isMarkerShown && <Marker position={position} icon={icon} />}
    </GoogleMap>
));

const FrontPage = styled.div`
    height: 100%;
    position: relative;
    // overflow: hidden;
    background: transparent;
    z-index: 3;

    ${props =>
        props.frontPage &&
        `
        position: unset;
    `}
`;

const VideoBackground = styled.video`
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    z-index: -1;
    outline: none;
    opacity: .4;
    ${props =>
        props.frontPage &&
        `
        display: none;
    `}
`;

const SectionMap = styled.div`
    width: 100%;
    height: ${props => props.height || "150px"};
    position: relative;
    display: block;
    z-index: 3;
    transition: 0.3s;

    > button {
        display: flex;
        background: rgba(0, 0, 0, 0.8);
        border: none;
        outline: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 30px;
        font-size: 28px;
        position: absolute;
        z-index: 1;

        img {
            width: 30%;
            @media only screen and (max-width: 1200px) {
                width: 50%;
            }
            @media only screen and (max-width: 576px) {
                width: 70%;
            }
        }
    }

    ${props =>
        props.frontPage &&
        `
        display: none;
    `}
`;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            on: false,
            height: "200px"
        };

        this.myRef = React.createRef();
        initializeReactGA()
    }

    handleClick = () => {
        // this.setState(({on}) => ({ height: '500px', on: !on }));
        if (this.state.on) {
            this.setState({ height: "200px" });
        } else {
            this.setState({ height: "500px" });
            $("html,body").animate({ scrollTop: $(document).height() }, 500);
            document.querySelector(".toggleMap").style.display = "none";
        }
        // this.setState(({on}) => ({ on: !on }));
    };
    componentDidMount() {
        window.onbeforeunload = function () {
            window.scrollTo(0,0);
        };

        window.addEventListener('orientationchange', () => {
            console.log('reload')
            window.location.reload();
        }, false);
        // alert($(window).innerWidth())
    }

    // componentWillMount() {
    //     // $(window).on("beforeunload", function() {
    //     //     $(window).scrollTop(0);
    //     // });
    // }

    // componentDidMount() {
    //     window.addEventListener("load", function() {
    //         // var paragraph = document.querySelector("p"),
    //         //   button = document.querySelector("button");
    //         // // Adding click event handler to button.
    //         // button.addEventListener("click", detectWebGLContext, false);
    //         // function detectWebGLContext () {
    //           // Create canvas element. The canvas is not added to the
    //           // document itself, so it is never displayed in the
    //           // browser window.
    //           var canvas = document.createElement("canvas");
    //           // Get WebGLRenderingContext from canvas element.
    //           var gl = canvas.getContext("webgl")
    //             || canvas.getContext("experimental-webgl");
    //           // Report the result.
    //           if (gl && gl instanceof WebGLRenderingContext) {
    //             alert("Congratulations! Your browser supports WebGL.")
    //           } else {
    //             alert("Failed to get WebGL context. "
    //               + "Your browser or device may not support WebGL.")
    //           }
    //         // }
    //       }, false);
    // }

    // componentDidMount() {
    //     window.addEventListener("load", function() {
    //         var paragraph = document.querySelector("p"),
    //           button = document.querySelector("button");
    //         // Adding click event handler to button.
    //         button.addEventListener("click", detectWebGLContext, false);
    //         function detectWebGLContext () {
    //           // Create canvas element. The canvas is not added to the
    //           // document itself, so it is never displayed in the
    //           // browser window.
    //           var canvas = document.createElement("canvas");
    //           // Get WebGLRenderingContext from canvas element.
    //           var gl = canvas.getContext("webgl")
    //             || canvas.getContext("experimental-webgl");
    //           // Report the result.
    //           if (gl && gl instanceof WebGLRenderingContext) {
    //             paragraph.innerHTML =
    //               "Congratulations! Your browser supports WebGL.";
    //           } else {
    //             paragraph.innerHTML = "Failed to get WebGL context. "
    //               + "Your browser or device may not support WebGL.";
    //           }
    //         }
    //       }, false);

    //     if(window.innerWidth > 1200) {
    //         smoothScroll();
    //         console.log('smooth scroll working')
    //     }  else {
    //         console.log('smooth scroll not working')
    //     }
    // }

    // componentDidUpdate() {

    //     cursorMouse();
    // }

    render() {
        return (
            // <Router>
            <div className="App" ref={this.myRef}>
                {/* <div class="cursor-dot-outline"></div>
                <div class="cursor-dot"></div> */}
                <Router>
                    <Navigation />
                    {/* <div id="parent-scroll"> */}
                    <div className="front-page">
                        <FrontPage>
                            {this.props.showBackground && (
                                <VideoBackground
                                    autoPlay
                                    loop
                                    playsInline
                                    muted
                                    id="video-bg"
                                >
                                    <source
                                        src="/assets/bg-content-desktop.mp4"
                                        type="video/mp4"
                                    />
                                    <source
                                        src="/assets/bg-content-mobile.mp4"
                                        type="video/mp4"
                                    />
                                </VideoBackground>
                            )}
                            {/* <div className="content content--canvas"></div> */}
                            <Route
                                path="/"
                                exact
                                strict
                                render={() => {
                                    return (
                                        <>
                                            <HomeAnimate />
                                            <WhatWeDo />
                                            <Gallery />
                                            <WhyUs />
                                            <Service />
                                            <Cave />
                                            <Client />
                                            <Contact />
                                        </>
                                    );
                                }}
                            />
                        </FrontPage>
                        {this.props.showMap && (
                            <>
                                <SectionMap
                                    height={this.state.height}
                                    onClick={this.handleClick}
                                >
                                    <button className="toggleMap">
                                        <img
                                            src="/assets/we_are_here.svg"
                                            alt=""
                                            srcSet=""
                                        />
                                    </button>
                                    <MyMapComponent isMarkerShown />
                                </SectionMap>
                            </>
                        )}
                    </div>
                    {/* </div> */}

                    {/* <Route path="/jobs" component={Jobs} /> */}
                    <Route
                        path="/exceptionalwork"
                        component={ExceptionalWork}
                    />
                </Router>
            </div>
            // </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        showMap: state.showMap,
        showBackground: state.showBackground
    };
}

export default connect(mapStateToProps)(App);
