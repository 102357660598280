import React from "react";
import { TimelineMax, TweenMax, Expo, Power0, TimelineLite } from "gsap/TweenMax";
import "../scss/HomeAnimate.scss";
import "../js/lettering.js";
import anime from "animejs/lib/anime.es.js";
import { connect } from "react-redux";

const $ = window.$;

class HomeAnimate extends React.Component {
    constructor(props) {
        super(props);

        this.animText = this.animText.bind(this);
        this.anim = this.anim.bind(this);
        this.changeText = this.changeText.bind(this);

        this.tl = new TimelineMax({repeat: -1, repeatDelay: 2})

        let vh = $(window).height() * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    componentDidMount() {
        // setTimeout(() => { this.changeText() }, 300)

        this.animText();
        this.anim();

        // alert($(window).height())
        // console.log('jq ', $(window).height())
        // console.log('vanilla ', window.innerHeight)

        // if(window.innerWidth > 576) {
        // }
        // const video = document.getElementById("video-cover");
        // video.play();

        this.props.dispatch({ type: "SHOW_MAP", payload: true });
        this.props.dispatch({ type: "SHOW_LOGO", payload: true });
        this.props.dispatch({ type: "SHOW_BACKGROUND", payload: true });

    }

    componentWillUnmount() {
        document.querySelector(".cursor-dot").style.display = "none";
        document.querySelector(".cursor-dot-outline").style.display = "none";
        // document.querySelector('.cursor-dot').style.top = (window.innerHeight / 2) + 'px';
        // document.querySelector('.cursor-dot').style.left = (window.innerWidth / 2) + 'px';
    }

    // animation text conspiracy and tag-line
    anim(){
        const conspi = document.querySelector(".section-homeanim svg")
        const conspiTH = document.querySelector(".section-homeanim svg #conspi-thai")
        const conspiEN = document.querySelector(".section-homeanim svg #conspi-eng")
        const tagline = document.querySelector(".section-homeanim .tag-line")

        this.tl
        .to(conspi, 5, {
            filter:"blur(0px)",
            opacity: 1,
            ease: Power0.easeNone
        })
        .to(conspiTH, 5,{
            y: 20,
            opacity: 0,
            delay: 3,
            ease: Power0.easeNone
        }).to(conspiEN, 5,{
            y: -20,
            opacity: 0,
            ease: Power0.easeNone
        }, "-=5")
        .from(tagline, 0.8, {
            opacity: 0,
        }).to(tagline, 5, {
            delay: 5,
            opacity: 0,
            filter:"blur(8px)",
            ease: Expo.easeInOut
        })
        
    };

    // Keep Scrolling
    animText() {
        const text = document.querySelector(".section-homeanim .text-animate");
        text.innerHTML = text.textContent.replace(
            /([^\x00-\x80]|\w)/g,
            "<span class='letter'>$&</span>"
        );

        TweenMax.to(text, 0.8, {
            delay: 2.5,
            opacity: 1,
            onComplete: () => {
                anime
                    .timeline({ loop: true })
                    .add({
                        targets: ".section-homeanim .text-animate .letter",
                        translateY: [0, -40],
                        translateZ: 0,
                        opacity: [1, 0],
                        easing: "easeOutExpo",
                        duration: 1400,
                        delay: function(el, i) {
                            return 300 + 30 * i;
                        }
                    })
                    .add({
                        targets: ".section-homeanim .text-animate .letter",
                        translateY: [40, 0],
                        opacity: [0, 1],
                        easing: "easeInExpo",
                        duration: 1200,
                        delay: function(el, i) {
                            return 100 + 30 * i;
                        }
                    });
            }
        });
    }

    split=()=> {
        const random = (min, max) => {
            return Math.random() * (max - min) + min;
        };

        $(".tagline").each(function(i) {
            $(this).addClass("separate");
            TweenMax.from($(this), 1.6, {
                opacity: 0,
                x: random(-500, 500),
                y: random(-500, 500),
                z: random(-500, 500),
                scale: 5,
                delay: i * 0.01,
                yoyo: true,
                ease: Expo.easeInOut
            });
        });
    }

    changeText() {
        const random = (min, max) => {
            return Math.random() * (max - min) + min;
        };

        $(".box svg path").each(function(i) {
            $(this).addClass("separate");
            TweenMax.from($(this), 1.6, {
                opacity: 0,
                x: random(-500, 500),
                y: random(-500, 500),
                z: random(-500, 500),
                scale: 5,
                delay: i * 0.01,
                yoyo: true,
                ease: Expo.easeInOut
            });
        });
    }

    render() {
        return (
            <section className="section-homeanim" id="home">
                <video autoPlay loop playsInline muted id="video-cover">
                    <source src="/assets/home-desktop.mp4" type="video/mp4" />
                    <source src="/assets/home-mobile.mp4" type="video/mp4" />
                    {/* <source src="/assets/mobile-vdo.mp4" type="video/mp4" /> */}
                </video>
                <p className="tag-line">A boutique, flexible, fast-moving team with big ideas.</p><div className="box">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 231.42 85.97"
                        >
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="port_show_all" data-name="port show all">
                                    <g id="conspi-thai">
                                        <path d="M36,17.46a7.41,7.41,0,0,0-3.53-2.06c3,1,3.42,4.73,3.42,8.06s-.47,7.07-3.42,8a7.27,7.27,0,0,0,3.53-2,8.41,8.41,0,0,0,0-12" />
                                        <path d="M28.51,15.4A7.46,7.46,0,0,0,25,17.46a8.41,8.41,0,0,0,0,12,7.31,7.31,0,0,0,3.53,2c-3-1-3.41-4.69-3.41-8s.46-7.09,3.41-8.06" />
                                        <path d="M12.75,16.28h-.26a1.48,1.48,0,0,1-1.13.48,3.9,3.9,0,0,1-1-.13,11,11,0,0,1-1.61-.56,10.94,10.94,0,0,0-2.16-.66,5,5,0,0,1,2.76,1.82,3.62,3.62,0,0,1,.74,2.18v8.05a3.57,3.57,0,0,1-.74,2.17,5,5,0,0,1-2.76,1.83,10.31,10.31,0,0,0,2.16-.67,14,14,0,0,1,1.61-.56,4.43,4.43,0,0,1,1-.12,1.48,1.48,0,0,1,1.13.48h.26Z" />
                                        <path d="M61,16.09h2.65v-.46H56.38c1.32.27,2,1.88,2,3.35v8.93c0,1.47-.63,3.09-2,3.35h7.25V30.8H61Z" />
                                        <path d="M50.86,28.14V15.63H45.57v.46h2.66V27.17c0,2.9,1.86,4.31,4.59,4.32-1.32-.26-2-1.88-2-3.35" />
                                        <path d="M3.62,23.7h2.1v-.45H2.64V18.73c0-1.44.64-3.06,2-3.32h0c-2.74,0-4.6,1.43-4.6,4.32V31.26H4.6C3.31,31,2.68,29.48,2.64,28V25.91a2.74,2.74,0,0,1,1-2.21" />
                                        <path d="M109.2 30.8 109.2 0.46 110.52 0.46 110.52 0 105.24 0 105.24 0.46 106.56 0.46 106.56 31.26 111.84 31.26 111.84 30.8 109.2 30.8z" />
                                        <path d="M99.08,19c0-1.47.64-3.08,2-3.35-.07,0-.06,0,0,0H93.8v.46h2.64V27.17c0,2.9,1.86,4.32,4.6,4.32-1.32-.26-2-1.88-2-3.35Z" />
                                        <path d="M228.76,30.81V19.72c0-2.89-1.86-4.31-4.59-4.32,1.32.27,2,1.88,2,3.35v9.16c0,1.47-.64,3.09-2,3.35.06,0,.06,0,0,0h7.25v-.47Z" />
                                        <path d="M228.76,10.84V6.55h0c0-1.47.63-3.09,2-3.35a6.12,6.12,0,0,0-2,.3,3.87,3.87,0,0,0-2.63,4v4.29h0c0,1.47-.64,3.08-2,3.35a6.29,6.29,0,0,0,2-.31,3.86,3.86,0,0,0,2.63-4" />
                                        <path d="M221.82 12.3 214.24 12.3 214.24 13.62 222.28 13.62 222.28 10.75 221.82 10.75 221.82 12.3z" />
                                        <path d="M222.09,7.24a1,1,0,1,0,0,2,1,1,0,0,0,0-2" />
                                        <path d="M220.62,15.63h-6.57v.46h.47a3.65,3.65,0,0,1,1,2.37H216a4,4,0,0,1,.95-2.3,3.84,3.84,0,0,1,1,2.48V30.81h-1.32v.45h5.28v-.45h-1.32Z" />
                                        <path d="M156.44,16.09h2.65v-.46h-7.25c1.32.27,2,1.88,2,3.35v8.93c0,1.47-.63,3.09-2,3.35h7.25V30.8h-2.65Z" />
                                        <path d="M147.3,19.88h0v-.39a3.86,3.86,0,0,0-2.64-4,6,6,0,0,0-2-.3c1.32.26,2,1.87,2,3.34h0v.4a3.85,3.85,0,0,0,2.64,4,6,6,0,0,0,2,.31c-1.32-.27-2-1.88-2-3.35" />
                                        <path d="M147.3,28.14V26.81c0-1.47.64-3.09,2-3.35a6,6,0,0,0-2,.3,3.94,3.94,0,0,0,0,7.42,6.29,6.29,0,0,0,2,.31c-1.32-.27-2-1.88-2-3.35" />
                                        <path d="M82.19,19.4v-.08c0,.14,0,.26,0,.39,0-.11,0-.21,0-.31" />
                                        <path d="M88.84,10.82h-.4a3.88,3.88,0,0,0-4,2.63,6,6,0,0,0-.3,2c.26-1.32,1.88-2,3.35-2h.39a3.87,3.87,0,0,0,4-2.63,6.29,6.29,0,0,0,.31-2c-.27,1.32-1.88,2-3.35,2" />
                                        <path d="M84.65,18.1a.14.14,0,0,0,0,.06.14.14,0,0,1,0-.07c0-.11-.07-.22-.12-.34l0-.09c-.06-.12-.12-.24-.19-.36-.59-.94-2-1.9-5.15-1.9-3.53,0-5.39,1.8-5.39,3.83,0,.86.3,1.71,1.46,1.71s1.66-.53,1.54-1.56a11.36,11.36,0,0,1-.17-1.8c0-1.18,1.15-1.72,2.67-1.72,2.28,0,2.93,1.26,2.93,3.46a4.58,4.58,0,0,1-.38,2c-.46.92-1.71,1.27-3.78,2a13.34,13.34,0,0,0-4,1.8,3.27,3.27,0,0,0-1.39,2.74c0,3.21,3.27,3.67,4.56,3.67-1.32-.27-2-1.88-2-3.35v-.37A3.73,3.73,0,0,1,77.89,24s2-.65,3.94-1.39a8.05,8.05,0,0,0,1.68-.85,3.28,3.28,0,0,0,1.28-2.67h0v0a3.8,3.8,0,0,0-.13-1m-.13-.37s0,0,0,0,0,0,0,0" />
                                        <path d="M169.6,17.3c.59-.94,2-1.9,5.15-1.9,3.52,0,5.39,1.8,5.39,3.83,0,.86-.3,1.71-1.46,1.71s-1.66-.53-1.54-1.56a11.36,11.36,0,0,0,.17-1.8c0-1.18-1.15-1.72-2.67-1.72-2.28,0-2.93,1.26-2.93,3.46a4.58,4.58,0,0,0,.38,2c.46.92,1.71,1.27,3.78,2a13.34,13.34,0,0,1,4,1.8,3.27,3.27,0,0,1,1.39,2.74c0,3.21-3.27,3.67-4.56,3.67,1.32-.27,2-1.88,2-3.35v-.37A3.74,3.74,0,0,0,176,24s-2-.65-3.94-1.39a8.05,8.05,0,0,1-1.68-.85,3.28,3.28,0,0,1-1.28-2.67,3.42,3.42,0,0,1,.49-1.79" />
                                        <path d="M128.7,15.4c1.33.26,2,1.88,2,3.35V27.9c0,1.48-.64,3.09-2,3.36h4.6V19.72c0-2.9-1.86-4.32-4.6-4.32" />
                                        <path d="M122.9,16.63c-.31.08-1.49.38-2.09-.36h-.26v5h2.63V19.4a3.63,3.63,0,0,1,.75-2.17,5,5,0,0,1,2.75-1.83,11.05,11.05,0,0,0-2.15.67,12.48,12.48,0,0,1-1.62.56" />
                                        <path d="M201.05,15.4c1.33.26,2,1.88,2,3.35V27.9c0,1.48-.64,3.09-2,3.36h4.6V19.72c0-2.9-1.86-4.32-4.6-4.32" />
                                        <path d="M195.25,16.63c-.31.08-1.49.38-2.09-.36h-.26v5h2.63V19.4a3.68,3.68,0,0,1,.74-2.17A5,5,0,0,1,199,15.4a10.84,10.84,0,0,0-2.15.67,12.48,12.48,0,0,1-1.62.56" />
                                    </g>
                                    <g id="conspi-eng">
                                        <path d="M36,56.53a7.41,7.41,0,0,0-3.53-2.06c3,1,3.42,4.73,3.42,8.07s-.47,7.06-3.42,8a7.36,7.36,0,0,0,3.53-2,8.41,8.41,0,0,0,0-12" />
                                        <path d="M28.51,54.47A7.46,7.46,0,0,0,25,56.53a8.41,8.41,0,0,0,0,12,7.41,7.41,0,0,0,3.53,2c-3-1-3.41-4.7-3.41-8s.46-7.1,3.41-8.07" />
                                        <path d="M123.66,54.7h0c1.33.27,2,1.88,2,3.36v9.15c0,1.47-.63,2.85-2,3.12h4.6V54.7Z" />
                                        <path d="M48.23,69.88H45.57v.46h7.25c-1.32-.27-2-1.88-2-3.35V58.06c0-1.47.64-3.08,2-3.35H45.57v.46h2.66Z" />
                                        <path d="M58.34,57.83V70.34h5.29v-.46H61V58.8c0-2.9-1.86-4.31-4.6-4.32,1.33.26,2,1.88,2,3.35" />
                                        <path d="M99.08 54.71 93.8 54.71 93.8 55.17 96.44 55.17 96.44 85.51 95.12 85.51 95.12 85.97 100.41 85.97 100.41 85.51 99.08 85.51 99.08 54.71z" />
                                        <path d="M109.2,69.88V58.8c0-2.9-1.86-4.31-4.6-4.32,1.33.27,2,1.88,2,3.35V67c0,1.47-.63,3.08-2,3.35.07,0,.06,0,0,0h7.24v-.46Z" />
                                        <path d="M145.66,69.87H143v.46h7.25c-1.32-.26-2-1.88-2-3.35V58.05c0-1.47.63-3.08,2-3.35H143v.46h2.65Z" />
                                        <path d="M154.8,66.09h0v.39a3.85,3.85,0,0,0,2.64,4,6,6,0,0,0,2,.31c-1.32-.27-2-1.88-2-3.35h0v-.39a3.87,3.87,0,0,0-2.64-4,6,6,0,0,0-2-.3c1.32.26,2,1.88,2,3.35" />
                                        <path d="M154.8,57.83v1.32c0,1.48-.64,3.09-2,3.36a6.29,6.29,0,0,0,2-.31,3.94,3.94,0,0,0,0-7.42,6,6,0,0,0-2-.3c1.32.26,2,1.88,2,3.35" />
                                        <path d="M73.17,56.38c.59-.94,2-1.9,5.15-1.9,3.52,0,5.39,1.8,5.39,3.83,0,.86-.31,1.71-1.46,1.71s-1.66-.53-1.55-1.56a9.86,9.86,0,0,0,.18-1.81c0-1.17-1.15-1.71-2.67-1.71-2.28,0-2.93,1.25-2.93,3.46a4.66,4.66,0,0,0,.37,2c.47.92,1.72,1.27,3.79,2a13.38,13.38,0,0,1,4,1.81,3.3,3.3,0,0,1,1.39,2.74c0,3.21-3.27,3.67-4.57,3.67,1.33-.27,2-1.88,2-3.36v-.36a3.73,3.73,0,0,0-2.61-3.77s-2-.65-3.94-1.39A8.05,8.05,0,0,1,74,60.84a3.28,3.28,0,0,1-1.28-2.67,3.42,3.42,0,0,1,.49-1.79" />
                                        <path d="M180.73,56.38c-.59-.94-2-1.9-5.15-1.9-3.53,0-5.39,1.8-5.39,3.83,0,.86.3,1.71,1.46,1.71,1,0,1.66-.53,1.54-1.56a11.4,11.4,0,0,1-.17-1.81c0-1.17,1.15-1.71,2.67-1.71,2.28,0,2.93,1.25,2.93,3.46a4.58,4.58,0,0,1-.38,2c-.46.92-1.71,1.27-3.78,2a13.38,13.38,0,0,0-4,1.81,3.27,3.27,0,0,0-1.39,2.74c0,3.21,3.27,3.67,4.56,3.67-1.32-.27-2-1.88-2-3.36v-.36a3.73,3.73,0,0,1,2.61-3.77s2-.65,3.94-1.39a8.05,8.05,0,0,0,1.68-.85,3.28,3.28,0,0,0,1.28-2.67,3.42,3.42,0,0,0-.49-1.79" />
                                        <path d="M4.6,54.48C1.86,54.49,0,55.9,0,58.8V70.34H4.6c-1.33-.27-2-1.88-2-3.36V57.83c0-1.47.63-3.09,2-3.35" />
                                        <path d="M10.13,66.56a3.69,3.69,0,0,1-.74,2.18,5,5,0,0,1-2.76,1.83,10.94,10.94,0,0,0,2.16-.67,12.36,12.36,0,0,1,1.61-.56c.31-.08,1.49-.38,2.09.35h.26v-5H10.13Z" />
                                        <path d="M9.39,56.39a3.62,3.62,0,0,1,.74,2.18v1.89h2.63v-5h-.26c-.6.73-1.78.43-2.09.35a11,11,0,0,1-1.61-.56,10.31,10.31,0,0,0-2.16-.66,5,5,0,0,1,2.76,1.82" />
                                        <path d="M195.53,67V57.83c0-1.47.64-3.09,2-3.35-2.74,0-4.6,1.42-4.6,4.32V70.34h4.6c-1.32-.27-2-1.88-2-3.36" />
                                        <path d="M203.29,69.34c.32-.08,1.49-.38,2.09.35h.27v-5H203v1.89a3.62,3.62,0,0,1-.74,2.18,5.07,5.07,0,0,1-2.75,1.83,11.05,11.05,0,0,0,2.15-.67,12.36,12.36,0,0,1,1.61-.56" />
                                        <path d="M203.29,55.79c.32.08,1.49.38,2.09-.35h.27v5H203V58.57a3.62,3.62,0,0,0-.74-2.18,5,5,0,0,0-2.75-1.82,10.4,10.4,0,0,1,2.15.66,11,11,0,0,0,1.61.56" />
                                        <path d="M224.17,54.7c.06,0,.06,0,0,0,1.32.27,2,1.88,2,3.35v9.16c0,1.47-.64,3.09-2,3.35,2.73,0,4.59-1.42,4.59-4.32V55.17h2.66V54.7Z" />
                                        <path d="M224.17,75.14v4.29h0c0,1.47-.64,3.08-2,3.35a6,6,0,0,0,2-.3,3.87,3.87,0,0,0,2.64-4v-4.3h0c0-1.47.64-3.07,2-3.34a6,6,0,0,0-2,.3,3.87,3.87,0,0,0-2.64,4" />
                                        <path d="M216,70.32c.06,0,.07,0,0,0" />
                                        <path d="M216.65,55.15H218V67.31c-.1,1.37-.73,2.77-1.95,3h4.59V55.15h1.32v-.46h-5.28Z" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                <div className="container">
                    
                    <h1 className="text-animate">KEEP SCROLLING</h1>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        showMap: state.showMap,
        showLogo: state.showLogo
    };
}

export default connect(mapStateToProps)(HomeAnimate);
