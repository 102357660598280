import React, { Component } from "react";
import "../scss/Gallery.scss";
import WorkOutter from "./WorkOutter.js";
import { connect } from "react-redux";
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "../js/debug.addIndicators.js";

class Gallery extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         isToggleOn: false,
    //         isNext: false,
    //         isPrev: false,

    //         portfolios: [],
    //         currentIndex: 1,
    //         hasMoreItems: true,
    //         nextHref: null,
    //         pageIndex: 1,
    //         title: "Title",
    //         company: "Company",
    //         content: "",
    //         categories: "",
    //         gallery: [],
    //         video: [],
    //         img: "Image",
    //         count: 0
    //     };
    // }

    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
        this.tl = new TimelineMax({ paused: false });
    }

    componentDidMount() {
        this.props.dispatch({ type: "SHOW_GALLERY", payload: true });
        this.props.dispatch({ type: "SHOW_WORK", payload: false });

        const g1 = document.querySelector(
            ".section-work svg #g1"
        );

        const g2 = document.querySelector(
            ".section-work svg #g2"
        );

        const grid = document.querySelector(
            ".section-work #work-out"
        );

        new ScrollMagic.Scene({
            triggerElement: ".section-work",
            triggerHook: 0.5,
            reverse: false
        })
            .setTween(
                this.tl
                    .from(g1, 0.6, {
                        opacity: 0,
                        xPercent: 100,
                        ease: Expo.easeOut
                    })
                    .from(
                        g2,
                        0.6,
                        {
                            opacity: 0,
                            xPercent: -100,
                            ease: Expo.easeOut
                        },
                        "-=0.6"
                    )
                    .from('.grid-work', 0.8, {
                        opacity: 0,
                        yPercent: 50,
                        ease: Expo.easeOut
                    })
            )
            // .addIndicators({ name: "mobile" })
            .addTo(this.controller);
    }

    render() {
        return (
            <section className="section-work" id="work">
                <div className="container">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1166.575"
                        height="196.1406"
                        viewBox="0 0 1166.575 196.1406"
                    >
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="defult">
                                <g id="g1">
                                <path
                                    d="M4.4272,119.5273v-1.7138l2.9986-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754H79.8291V52.4082H78.5439L76.83,47.5527C71.4033,31.8438,61.6929,25.1318,47.5547,25.1318H42.5566c-5.998,0-6.8549,2.2852-6.8549,21.5635V69.26H42.271c7.2832,0,13.5664-4.1416,16.7085-14.4239l.9995-3.1416h1.2852V88.5381H59.979L58.98,85.3965C55.8374,75.1143,49.5542,70.9727,42.271,70.9727H35.7017V96.1074c0,19.2783.8569,21.5635,6.7119,21.5635h7.997c15.4234,0,22.7066-7.7119,29.2759-26.7051L81.4,86.11h1.2852v33.4169Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M165.94,119.5273v-1.7138l3.2847-.1426c6.7119-.2861,4.4273-6.5693-6.4263-22.7061L149.66,75.2568l-7.9971,11.4248c-8.7114,12.1387-10.9966,18.2793-10.9966,22.7061,0,4.8555,2.5708,8.1406,6.5693,8.2832l3.2847.1426v1.7138H108.8174v-1.7138l1.4277-.1426c5.57-.5713,11.853-6.998,29.1328-31.418L148.375,73.4,131.9521,48.5527c-11.2817-16.8515-15.5659-22.707-21.707-23.4209l-1.1421-.1425V23.2754h43.5562v1.7139l-3.1421.1425c-7.9971.4287-5.5693,6.1407,5.7124,22.7061l11.5674,17.4228,6.1406-9.14c7.8545-11.2822,10.71-17.8506,10.71-22.85,0-4.5694-2.4277-7.9971-6.7119-8.14l-3.1416-.1425V23.2754h31.8462v1.7139l-1.4282.1425c-5.4268.4287-11.9961,6.9981-28.8472,31.418L167.94,66.9746,186.0757,94.251c11.4248,16.9931,15.9946,22.9912,22.4209,23.42l1.1426.1426v1.7138Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M303.1748,121.3838c-5.1411-4.8555-8.5684-6.2832-11.4248-6.2832-1.999,0-3.7129.7139-6.2832,2.1416a29.4711,29.4711,0,0,1-15.709,4.1416c-29.561,0-46.8408-23.1348-46.8408-49.84,0-26.5615,16.8516-50.125,45.9839-50.125A31.1951,31.1951,0,0,1,284.61,25.418c2.4277,1.2851,3.9985,2.1416,5.8549,2.1416,2.7134,0,5.4268-1.5713,9.8536-6.1407h1.1425V52.98h-1.4282l-1.5708-4.8555c-4.2842-13.2812-14.709-24.8486-28.5615-24.8486-18.85,0-24.7056,20.707-24.7056,48.126s6.1406,48.1259,25.5625,48.1259c14.7095,0,24.9912-12.9951,30.418-29.1328l1.7139-4.8554h1.4277v35.8447Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M333.8774,119.5273v-1.7138l2.9986-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754h75.4019V52.4082h-1.2852L406.28,47.5527c-5.4268-15.7089-15.1372-22.4209-29.2754-22.4209h-4.9981c-5.998,0-6.8549,2.2852-6.8549,21.5635V69.26h6.5693c7.2832,0,13.5664-4.1416,16.7085-14.4239l.9995-3.1416h1.2852V88.5381h-1.2852l-.9995-3.1416c-3.1421-10.2822-9.4253-14.4238-16.7085-14.4238h-6.5693V96.1074c0,19.2783.8569,21.5635,6.7119,21.5635h7.997c15.4234,0,22.7066-7.7119,29.2759-26.7051L410.85,86.11h1.2852v33.4169Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M493.6763,79.3984H473.6831v16.709c0,19.5645,1.5708,21.1348,12.1387,21.5635l2.999.1426v1.7138H442.4087v-1.7138l2.9985-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9985-.1425V23.2754h50.9819c22.8491,0,36.273,10.4248,36.273,28.4189C529.6636,68.6885,517.668,79.3984,493.6763,79.3984ZM490.249,25.1318h-9.7109c-5.9981,0-6.855,2.2852-6.855,21.5635V77.6846H490.106c12.1386,0,16.5654-10.5674,16.5654-26.2764C506.6714,36.8418,501.959,25.1318,490.249,25.1318Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M578.2153,119.5273v-1.7138l2.9991-.1426c6.4262-.2861,7.8544-1.999,7.8544-21.5635V46.41c0-19.2793-.8569-21.2784-6.5693-21.2784h-2.4277c-8.4258,0-16.5655,7.8545-22.2779,25.2774l-1.5708,4.8554h-1.4282V23.2754h88.9688V55.2646h-1.2852l-1.7139-4.8554c-5.7119-17.4229-13.852-25.2774-22.2778-25.2774h-2.4277c-5.5694,0-6.4263,1.9991-6.4263,21.2784V96.1074c0,19.5645,1.4282,21.2774,7.7114,21.5635l2.9991.1426v1.7138Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M666.3262,119.5273v-1.7138l2.9985-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9985-.1425V23.2754h42.1279v1.7139l-2.999.1425c-6.4263.2862-7.8545,1.9991-7.8545,21.5635V96.1074c0,19.5645,1.4282,21.2774,7.8545,21.5635l2.999.1426v1.7138Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M782.8555,121.3838c-31.7032,0-49.84-22.8486-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8393,22.5635,49.8393,49.84S814.7012,121.3838,782.8555,121.3838Zm0-98.1084c-20.85,0-27.562,20.4219-27.562,48.126,0,27.4189,6.7119,48.1259,27.562,48.1259S810.417,99.1055,810.417,71.4014C810.417,43.9824,803.7051,23.2754,782.8555,23.2754Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M937.9426,120.3838,870.1094,33.5576V83.1113c0,17.1367,6.4262,34.1309,15.8515,34.56l2.9991.1426v1.7138H855.9712v-1.7138l1.5708-.1426c6.4268-.5713,10.4253-11.2822,10.4253-34.56V45.41c0-18.4219-2.8565-19.9922-9.4253-20.2784l-2.5708-.1425V23.2754h32.2744l57.98,75.26V58.5488c0-16.4228-6.1416-32.9883-15.8518-33.417l-2.999-.1425V23.2754h32.9885v1.7139l-1.5713.1425c-6.8545.5713-10.4248,11.4248-10.4248,33.417v61.835Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M1030.1965,119.5273v-1.7138l3.7129-.1426c7.5684-.2861,6.8545-3.999.1426-21.85l-5.2832-13.8525H996.78l-1.999,5.57a48.887,48.887,0,0,0-3.2851,17.5644c0,6.8555,2.4277,12.2813,8.997,12.5674l2.9991.1426v1.7138H971.36v-1.7138l1.5713-.1426c5.7119-.4287,9.2822-2.999,19.707-30.7031l24.42-64.5489h9.14l29.5615,72.4024c8.2832,20.4219,10.14,22.1357,15.709,22.85l1.1426.1426v1.7138ZM997.4934,80.2559h30.5606l-15.4229-40.8428Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M1092.0305,119.5273v-1.7138l2.9981-.1426c6.2841-.2861,7.7119-1.999,7.7119-21.5635V46.6953c0-19.5644-1.4278-21.2773-7.7119-21.5635l-2.9981-.1425V23.2754h43.127v1.7139l-2.9991.1425c-7.4257.2862-8.8535,1.9991-8.8535,21.5635V96.1074c0,19.2783.8565,21.5635,6.7119,21.5635H1137.3c16.5655,0,22.8487-7.5684,30.7032-30.418l1.7138-4.8554h1.2852v37.13Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                </g>
                                <g id="g2">
                                <path
                                    d="M434.1992,216.7031l-26.8476-75.831L383.36,216.7031h-9.2823l-21.4214-72.2607c-6.1406-20.85-7.997-22.4209-14.2807-23.1348l-1.1421-.1426v-1.7138h42.27v1.7138l-3.2842.1426c-7.8545.2862-7.8545,4.2842-2.7134,21.9922l14.5665,50.84,23.7055-75.6884h9.2827l26.2764,76.1162L458.6191,152.44a81.3756,81.3756,0,0,0,2.4278-18.2793c0-7.4258-2.856-12.5664-9.711-12.8526l-2.999-.1426v-1.7138h31.5606v1.7138l-1.5708.1426c-6.4263.5713-10.1392,5.1416-17.4224,31.7031l-17.4229,63.6924Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M545.3027,217.56c-31.7031,0-49.84-22.8487-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8394,22.5635,49.8394,49.84S577.1484,217.56,545.3027,217.56Zm0-98.1084c-20.85,0-27.562,20.4218-27.562,48.1259,0,27.419,6.7119,48.126,27.562,48.126s27.5616-20.4219,27.5616-48.126C572.8643,140.1582,566.1523,119.4512,545.3027,119.4512Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M690.6792,215.7031c-1.1421-2.7129-2.856-6.2832-5.7119-11.9961-2.5708-5.4267-6.7119-14.1377-9.14-18.5644-7.7119-13.9951-10.9961-16.5655-19.1362-16.5655H650.979v23.7061c0,19.5645,1.4282,21.2773,7.8545,21.5635l2.999.1426v1.7138H619.7046v-1.7138l2.9985-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9985-.1426v-1.7138h52.1245c20.564,0,34.2734,7.8545,34.2734,23.9912,0,11.4248-8.14,19.4219-20.5639,22.707a72.37,72.37,0,0,1-19.9932,2.4277c17.5654,2.7129,26.2764,7.5684,32.7026,18.5645l6.712,11.5674c6.4262,11.1387,10.14,14.9951,14.4238,15.1377l2.57.1426v1.7138ZM668.83,121.3076H657.834c-5.9981,0-6.855,2.2852-6.855,21.5635v23.9922h15.4233c13.1382,0,17.565-9.2822,17.565-23.1348C683.9673,128.7334,678.3979,121.3076,668.83,121.3076Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                <path
                                    d="M809.35,215.7031c-1.2856-2.8564-2.999-6.4267-5.8555-12.1386-2.8559-5.2842-7.4257-13.8526-10.1391-18.4219-6.5694-11.2822-9.2822-13.71-15.9942-13.71h-5.2841v20.8506c0,19.5645,1.4282,21.2773,7.8545,21.5635l2.999.1426v1.7138h-42.128v-1.7138l2.9986-.1426c6.2837-.2862,7.7119-1.999,7.7119-21.5635V142.8711c0-19.5645-1.4282-21.2773-7.7119-21.5635l-2.9986-.1426v-1.7138H782.93v1.7138l-2.999.1426c-6.4263.2862-7.8545,1.999-7.8545,21.5635v26.7051h3.2846c6.2837,0,11.1392-1.7139,22.9917-14.4229l7.2832-7.5693c14.1382-14.8516,10.5679-25.8477.857-26.2764l-2.9991-.1426v-1.7138h39.5577v1.7138l-2.1421.1426c-6.1407.2862-13.5664,6.4268-34.1309,27.99l-5.5693,5.8554c-1.7139,1.8565-3.9986,4.2842-6.2837,6.5684,6.7119,2.999,10.9961,7.4267,18.8506,20.9931,3.2846,5.5694,8.14,13.4239,10.2822,17.1368,6.2832,10.5673,9.8535,13.8525,13.9951,13.9951l2.57.1426v1.7138Z"
                                    transform="translate(-4.4272 -21.4189)"
                                />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <WorkOutter id="work-out"/>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        showGallery: state.showGallery,
        showWork: state.showWork
    };
}

export default connect(mapStateToProps)(Gallery);
