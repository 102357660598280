import React from "react";
import { TimelineMax, Expo, Circ } from "gsap";
import "../scss/PortalGallery.scss";
import Carousel from "./Carousel";
import { stopVideo } from "../js/work.js";
import { enableBodyScroll } from "body-scroll-lock";

export default class PortalGallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenPortal: false,
            isClosePortal: false,
            isNext: false,
            isPrev: false,
            isFullSlide: false,
            isFullToggle: false,
            isHasMore: false,
            dataIndex: 0,
            fakeBankGallery: false
        };

        this.openPreview = null;
        this.closeRef = null;
        this.containerLeftRef = null;
        this.containerRightRef = null;
        this.textRef = null;
        this.btnNextPrevRef = null;
        this.fullSlide = null;
        this.tlHidden1 = null;
    }

    componentDidMount() {
        // window.addEventListener("resize", () => {
        //     let vh = window.innerHeight * 0.01;
        //     document.documentElement.style.setProperty("--vh", `${vh}px`);
        // });

        this.fullSlide = new TimelineMax({ paused: true });
        this.tlHidden1 = new TimelineMax({ paused: true });
        this.openPreview = new TimelineMax({
            paused: true,
            onStart: () => {
                this.refs.carousel.resetSlideGallery();
            }
        });
        this.openPreview
            .from(
                this.containerLeftRef,
                1.4,
                {
                    y: 30,
                    opacity: 0,
                    ease: Circ.easeOut
                },
                .5
            )
            // .staggerFrom(
            //     ".text-stagger",
            //     1.4,
            //     {
            //         // xPercent: -100,
            //         opacity: 0,
            //         y: 15,
            //         ease: Circ.easeOut
            //     },
            //     0.11,
            //     "-=.8"
            // )
            .from(
                this.btnNextPrevRef,
                0.4,
                {
                    opacity: 0,
                    y: 20,
                    ease: Circ.easeOut
                },
                "-=1"
            )
            .from(
                this.closeRef,
                0.4,
                {
                    opacity: 0,
                    ease: Circ.easeOut
                },
                "-=.8"
            )
            .to(
                this.closeRef,
                0.4,
                {
                    transform: "rotate(45deg)",
                    ease: Circ.easeOut
                },
                // "+=0.4"
            )
            // .reverse()
            .pause();

        //  Click to see portfolio full slide
        // this.fullSlide
        //     .to(this.containerRightRef, 0.4, {
        //         opacity: 0,
        //         ease: Expo.easeOut
        //     }, "-=0.2")
        //     .to(this.btnNextPrevRef, 0.4, {
        //         opacity: 0,
        //         ease: Expo.easeOut
        //     }, "-=0.2")
        //     .to(this.containerLeftRef, 0.4, {
        //         opacity: 0,
        //         ease: Expo.easeOut
        //     }, "-=0.2")
        //     .to(this.btnNextPrevRef, 0.1, {
        //         display: "none"
        //     })
        //     .to(this.containerRightRef, 0.1, {
        //         display: "none"
        //     })
        //     .to("#portal-container", 0.1, {
        //         justifyContent: "center"
        //     })
        //     .to(this.containerLeftRef, 0.1, {
        //         width: "100%",
        //         padding: 0
        //     })
        //     .to(this.containerLeftRef, 0.4, {
        //         delay: 0.25,
        //         opacity: 1,
        //         ease: Expo.easeOut
        //     })
        //     .pause();
    }

    toggleModal = id => {
        if (id) {
            this.setState(({ isOpenPortal }) => ({
                isOpenPortal: true,
                dataIndex: id,
                fakeBankGallery: false
            }));
        } else {
            this.setState(({ isOpenPortal }) => ({
                isOpenPortal: true
            }));
        }
    };

    handleCloseClick = () => {
        this.setState({
            isClosePortal: true,
            isNext: false,
            isPrev: false,
            // isFullToggle: false,
            isOpenPortal: false,
            // isFullSlide: false,
            fakeBankGallery: true
        });

        stopVideo();
        enableBodyScroll(document.querySelector(".portal"));
        const navigation = document.querySelector(".navigation");
        navigation.style.display = "block";

        document.querySelector(".portal").scrollTo(0, 0); // reset to scroll to top of div when click top see portfolio
        document.querySelector('html').style.overflowY = "scroll";
    };

    handleNextClick = () => {
        // console.log("next...", this.state.isNext);
        var i = parseInt(this.state.dataIndex) + 1;
        document.querySelector("#portal-container").classList.add("fade");
        // console.log("next...", i);
        // this.setState({
        //     fakeBankGallery: true
        // });

        setTimeout(() => {
            // console.log('next timeout ...')
            this.setState({
                isClosePortal: false,
                isOpenPortal: true,
                isNext: true,
                isPrev: false,
                // isFullToggle: false,
                dataIndex: i,
                // fakeBankGallery: true
            });
        }, 250);

        stopVideo();
        document.querySelector(".portal").scrollTo(0, 0);
    };

    handlePreviousClick = () => {
        var i = parseInt(this.state.dataIndex) - 1;
        document.querySelector("#portal-container").classList.add("fade");

        setTimeout(() => {
            this.setState({
                isClosePortal: false,
                isOpenPortal: true,
                isNext: false,
                isPrev: true,
                // isFullToggle: false,
                dataIndex: i
            });
        }, 250);

        stopVideo();
        document.querySelector(".portal").scrollTo(0, 0);
    };

    // handleClickFullSlide = () => {
    //     this.setState({
    //         isFullSlide: !this.state.isFullSlide,
    //         isFullToggle: !this.state.isFullToggle
    //     });

    //     stopVideo();

    //     // console.log('fullslide after click', this.state.isFullSlide)
    // };

    componentDidUpdate(prevProps, prevState) {

        /**
         * if portal is close
         * else portal is open
         *
         * if portal is open check isNext == false || isPrev == false
         * 1 isNext or isPrev wasn't click seek fullSlide = 0 & openpreview seek 0 and play open preview
         * 2 isNext or isPrev was click add class fade and set timeout everything to 0 and then play
         */
        if (!this.state.isOpenPortal) {
            // console.log("portal close...");
            this.fullSlide.seek(0);
            this.openPreview.seek(0);
            this.fullSlide.pause();
            this.openPreview.pause();
        } else {
            // console.log("portal open...");

            if (!this.state.isFullToggle) {
                // console.log(
                //     "portal open fullSlide close",
                //     this.state.isFullToggle
                // );

                // console.log('xxxx', document.querySelector('.slide-preview'))
                // document.querySelector('.slide-preview').addEventListener('click', ()=> {
                //     // document.querySelector('.slide-preview .slick-slide.slick-active.slick-current')
                //     // alert('click...')
                //     console.log('xxx' , document.querySelector('.slide-preview .slick-slide.slick-active.slick-current video'))
                //     const current_video = document.querySelector('.slide-preview .slick-slide.slick-active.slick-current video');
                //     if(!current_video) {
                //         console.log('stop')
                //         const videos = document.querySelectorAll('video')
                //         videos.forEach((video) => {
                //             video.load();
                //             video.currentTime = 0;
                //         });
                //     } else {
                //         console.log('play')
                //     }
                // })


                // document.querySelector('.slide-preview').addEventListener('touchstart', ()=> {
                //     // document.querySelector('.slide-preview .slick-slide.slick-active.slick-current')
                //     // console.log('touch' , document.querySelector('.slide-preview .slick-slide.slick-active.slick-current video'))
                //     const current_video = document.querySelector('.slide-preview .slick-slide.slick-active.slick-current video');
                //     // alert(current_video)
                //     if(!current_video) {
                //         // alert('stop')
                        
                //         const videos = document.querySelectorAll('video')
                //         videos.forEach((video) => {
                //             video.load();
                //             video.pause();
                //             video.currentTime = 0;
                //         });
                //     } else {
                //         // alert('play')
                //     }
                // })
                
                if (!this.state.isNext || !this.state.isPrev) {
                    // console.log("click next prev...");
                    // this.openPreview.pause()
                    document
                        .querySelector("#portal-container")
                        .classList.add("fade");

                    setTimeout(() => {
                        // console.log('did update...')
                        document
                            .querySelector("#portal-container")
                            .classList.remove("fade");
                        this.fullSlide.seek(0);
                        this.fullSlide.pause();
                        this.openPreview.seek(0);
                        this.openPreview.play();
                    }, 100);
                }
            } else {
                // console.log("portal open fullSlide open");
                this.openPreview.seek(6);
                this.openPreview.pause();
                this.fullSlide.play();
            }
        }

        if (prevState.isOpenPortal !== this.state.isOpenPortal) {
            // open modal
            if (this.state.isOpenPortal) {
                // console.log("gsap okk");
                // console.log("current portal ", this.state.isOpenPortal);
                this.openPreview.reversed(false);
                // this.fullSlide.reversed(false);
            }
        }

        if (prevProps.isOpen !== this.props.isOpen) {
            // open modal
            this.openPreview.reversed(false);
        }
    }

    render() {
        let data = this.props.portfolios[this.state.dataIndex]
            ? this.props.portfolios[this.state.dataIndex]
            : {
                  title: "Project Name",
                  company: "Developer",
                  gallery: [],
                  video: [],
              };


        return (
            <div
                className={this.state.isOpenPortal ? "portal active" : "portal"}
            >
                
                <div className="container-wrapper" id="portal-container">
                    <div className="wrapper-1">
                        <div className="container-text">
                            <h1>{data.title}</h1>
                            <p>{data.company}</p>
                        </div>

                        <button
                            className="closePortal"
                            onClick={this.handleCloseClick}
                            ref={e => (this.closeRef = e)}
                        />
                    </div>
                    <div className="wrapper-container">
                        <div className="wrapper-portal">
                            <div
                                className="container-left"
                                ref={e => (this.containerLeftRef = e)}
                            >
                                <div className="wrapper">
                                    <Carousel
                                        images={this.state.fakeBankGallery ? [] : data.gallery}
                                        videos={data.video}
                                        isOpenPortal={this.state.isOpenPortal}
                                        ref="carousel"
                                    />

                                    {/* <button
                                        // className="btn-fullSlide"
                                        className={
                                            this.state.isFullToggle
                                                ? "btn-fullSlide active"
                                                : "btn-fullSlide"
                                        }
                                        onClick={this.handleClickFullSlide}
                                    ></button> */}
                                </div>
                            </div>

                            {/* <div
                                className="container-right"
                                ref={e => (this.containerRightRef = e)}
                            >
                                <div className="wrapper">
                                    <div className="hidden">
                                        <h1
                                            className="text-title text-stagger"
                                            ref={e => (this.textRef = e)}
                                        >
                                            {data.title}
                                        </h1>
                                    </div>
                                    <div className="hidden">
                                        <h1
                                            className="text-company text-stagger"
                                            ref={e => (this.textRef = e)}
                                        >
                                            {data.company}
                                        </h1>
                                    </div>
                                    <div className="hidden">
                                        <p
                                            className="text-description text-stagger"
                                            ref={e => (this.textRef = e)}
                                        >
                                            {content.replace(/<[^>]+>/g, "")}
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div
                        className="wrapper-btn"
                        ref={e => (this.btnNextPrevRef = e)}
                    >
                        {this.state.dataIndex > 0 && (
                            <div
                                className="btn prev"
                                onClick={this.handlePreviousClick}
                            ></div>
                        )}
                        <div className="count">
                            <p>{parseInt(this.state.dataIndex) + 1}</p>
                            <span></span>
                            <p>{this.props.portfolios.length}</p>
                        </div>
                        {this.state.dataIndex <
                            this.props.portfolios.length - 1 && (
                            <div
                                className="btn next"
                                onClick={this.handleNextClick}
                            ></div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
