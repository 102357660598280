import React, { Component } from "react";
import "../scss/Contact.scss";
import * as ScrollMagic from "scrollmagic";
import "../js/debug.addIndicators.js";
import { TweenMax, TimelineMax, Expo, Power0 } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default  class Contact extends React.Component {
  static defaultProps = {
    // 13.736326, 100.576293
    center: { lat: 13.7365291, lng: 100.5761017 },
    zoom: 18
  };

  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
    this.tl = new TimelineMax({ paused: false });
  }

  componentDidMount() {
    const grCon = document.getElementById("g-con");
    const grTact = document.getElementById("g-tact");

    const grConMobile = document.getElementById("g-con-mobile");
    const grTactMobile = document.getElementById("g-tact-mobile");

    // Desktop
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        TweenMax.from(
          grCon,
          0.8,
          {
            opacity: 0,
            y: 83,
            ease: Expo.easeOut
          },
          "+=0.8"
        )
      )
      // .addIndicators({ name: "desktop" })
      .addTo(this.controller);

    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        TweenMax.from(
          grTact,
          0.8,
          {
            opacity: 0,
            y: -83,
            ease: Expo.easeOut
          },
          "+=0.8"
        )
      )
      // .addIndicators({ name: "desktop" })
      .addTo(this.controller);

    // Mobile
    // Con
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        this.tl
          .from(grConMobile, 0.8, {
            opacity: 0,
            xPercent: 100,
            ease: Expo.easeOut
          })
          .to(grConMobile, 0.8, {
            opacity: 0.28,
            delay: 1,
            ease: Power0.easeNone
          })
      )
      // .addIndicators({ name: "mobile conxxxx" })
      .addTo(this.controller);

    // Tact
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        this.tl
          .from(
            grTactMobile,
            0.8,
            {
              opacity: 0,
              xPercent: -100,
              ease: Expo.easeOut
            },
            "-=2.6"
          )
          .to(
            grTactMobile,
            0.8,
            {
              opacity: 0.28,
              delay: 1,
              ease: Power0.easeNone
            },
            "-=1.8"
          )
      )
      // .addIndicators({ name: "mobile tact" })
      .addTo(this.controller);

    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        this.tl.staggerFrom(".hidetext", 0.8, { opacity: 1, y: "-100%" }, 0.2)
      )
      .addTo(this.controller);

    this.triggerAnim(".nyText-1", 0.8, -5);
    this.triggerAnim(".nyText-2", 0.8, -5);
    this.triggerAnim(".bkkText", 0.8, 5);
    this.triggerAnim(".plusCon");
    this.triggerAnim(".plusNy", 0.5);
    this.triggerAnim(".plusBkk", 1);
    this.triggerAnim(".plusFb", 1.5);

    this.triggerAnim(".fbIcon", 2);

    this.triggerSlideUp();
    this.triggerSlideLeft();

    // const nyText = document.getElementsByClassName("nyText");
    // VanillaTilt.init(document.querySelector(".nyText"), {
    //   max: 30,
    //   speed: 3000
    // });
  }

  triggerAnim = (el, d, y) => {
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        TweenMax.to(
          el,
          0.8,
          {
            opacity: 1,
            delay: d,
            y: y,
            ease: Expo.easeOut
          },
          "+=0.8"
        )
      )
      // .addIndicators({name: "xxxx"})
      .addTo(this.controller);
  };

  triggerSlideUp = () => {
    const slide = document.querySelectorAll(".slide-up");
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        TweenMax.staggerFrom(
          slide,
          0.4,
          {
            yPercent: 100,
            delay: 1.2,
            ease: Expo.easeOut
          },
        )
      )
      // .addIndicators({name: "xxxx"})
      .addTo(this.controller);
  };

  triggerSlideLeft = () => {
    const slide = document.querySelectorAll(".slide-left");
    new ScrollMagic.Scene({
      triggerElement: ".section-contact",
      triggerHook: 0.1,
      reverse: false
    })
      .setTween(
        TweenMax.staggerFrom(
          slide,
          0.6,
          {
            xPercent: -100,
            delay: 1.2,
            ease: Expo.easeOut
          },
          0.1, "-=0.2"
        )
      )
      // .addIndicators({name: "xxxx"})
      .addTo(this.controller);
  };

  render() {
    return (
      <section className="section-contact" id="contact">
        <div className="container">
          <div className="wrapper-description">
            
            <img className="plusCon" src="/assets/plus_silver.svg" alt="" />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1244.8 339.75">
              <g id="Layer_2" data-name="Layer 2">
                <g id="defult">
                  <g id="g-con">
                    <path
                      id="ch-c-1"
                      d="M49.7,0C20.1.09,0,15.39,0,46.7V171.43H49.7c-.48,0-.52,0,0,0-14.32-2.88-21.2-20.32-21.2-36.22v-99C28.5,20.32,35.38,2.89,49.7,0"
                    />
                    <path
                      id="ch-c-2"
                      d="M109.46,130.63a39.23,39.23,0,0,1-8,23.5,53.87,53.87,0,0,1-29.78,19.75c8.59-1.5,16.15-4.39,23.28-7.21a135.46,135.46,0,0,1,17.42-6.06c3.42-.83,16.11-4.08,22.61,3.86h2.84l.07-54.3H109.47Z"
                    />
                    <path
                      id="ch-c-3"
                      d="M101.46,19.75a39.23,39.23,0,0,1,8,23.5V63.71H137.9l-.07-54.3H135c-6.49,8-19.19,4.68-22.6,3.86A134.59,134.59,0,0,1,95,7.21C87.83,4.39,80.27,1.49,71.68,0a53.85,53.85,0,0,1,29.78,19.75"
                    />
                    <path
                      id="ch-o"
                      d="M264.51,173.53c-54.29,0-85.34-39.12-85.34-85.82S210.22,2.37,264.51,2.37C319,2.37,349.84,41,349.84,87.71S319,173.53,264.51,173.53Zm0-168c-35.7,0-47.19,35-47.19,82.4,0,47,11.49,82.4,47.19,82.4s47.18-35,47.18-82.4C311.69,41,300.2,5.55,264.51,5.55Z"
                    />
                    <path
                      id="ch-n"
                      d="M530,171.82,413.89,23.16V108c0,29.34,11,58.44,27.14,59.17l5.14.25v2.93H389.68v-2.93l2.69-.25c11-1,17.85-19.31,17.85-59.17V43.45c0-31.54-4.89-34.23-16.13-34.72l-4.41-.24V5.55h55.26l99.27,128.86V66c0-28.12-10.51-56.49-27.14-57.22l-5.13-.24V5.55h56.48V8.49l-2.69.24C554,9.71,547.88,28.29,547.88,66V171.82Z"
                      fill="none"
                      stroke="#927c60"
                      strokeMiterlimit="10"
                    />
                  </g>

                  <g id="g-tact">
                    <path
                      id="ch-t-1"
                      d="M588.07,336.57v-2.94l5.14-.24c11-.49,13.45-3.42,13.45-36.92V211.38c0-33-1.47-36.43-11.25-36.43h-4.16c-14.42,0-28.36,13.45-38.14,43.28l-2.69,8.31H548V171.77H700.3v54.77h-2.2l-2.93-8.31C685.39,188.4,671.45,175,657,175h-4.15c-9.54,0-11,3.42-11,36.43v85.09c0,33.5,2.44,36.43,13.2,36.92l5.13.24v2.94Z"
                      fill="none"
                      stroke="#927c60"
                      strokeMiterlimit="10"
                    />
                    <path
                      id="ch-a"
                      d="M810.57,336.57v-2.94l6.36-.24c13-.49,11.73-6.85.24-37.41l-9-23.72H753.36l-3.43,9.54a83.74,83.74,0,0,0-5.62,30.07c0,11.74,4.16,21,15.4,21.52l5.14.24v2.94h-55v-2.94l2.69-.24c9.78-.73,15.9-5.14,33.75-52.57L788.08,170.3h15.65l50.61,124c14.18,35,17.36,37.9,26.89,39.12l2,.24v2.94Zm-56-67.24H806.9L780.5,199.4Z"
                    />
                    <path
                      id="ch-c"
                      d="M1048,339.75c-8.8-8.32-14.67-10.76-19.56-10.76-3.42,0-6.36,1.22-10.76,3.67-7.33,4.4-15.89,7.09-26.89,7.09-50.62,0-80.2-39.61-80.2-85.34,0-45.48,28.85-85.82,78.73-85.82a53.34,53.34,0,0,1,26.89,6.85c4.16,2.2,6.85,3.66,10,3.66,4.65,0,9.29-2.69,16.87-10.51h2v54h-2.45l-2.69-8.32c-7.33-22.73-25.18-42.54-48.9-42.54-32.27,0-42.3,35.45-42.3,82.4s10.51,82.4,43.77,82.4c25.18,0,42.79-22.25,52.08-49.88l2.93-8.32h2.45v61.38Z"
                    />
                    <path
                      id="ch-t-2"
                      d="M1132.57,336.57v-2.94l5.14-.24c11-.49,13.45-3.42,13.45-36.92V211.38c0-33-1.47-36.43-11.25-36.43h-4.16c-14.42,0-28.36,13.45-38.14,43.28l-2.69,8.31h-2.45V171.77H1244.8v54.77h-2.2l-2.93-8.31C1229.89,188.4,1216,175,1201.52,175h-4.15c-9.54,0-11,3.42-11,36.43v85.09c0,33.5,2.44,36.43,13.2,36.92l5.13.24v2.94Z"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <div className="new-york">
              <h2 className="nyText-1">NEW</h2>
              <h2 className="nyText-2">YORK</h2>
              <img className="plusNy" src="/assets/plus_gold.svg" alt="" />
              <ul className="nyContactLeft">
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="tel:+19173740409">+19 17 374 0409</a>
                  </li>
                </div>
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="mailto:ny@conspiracyplus.com">
                      ny@conspiracyplus.com
                    </a>
                  </li>
                </div>
              </ul>
              <ul className="nyContactRight">
                <div className="hidden">
                  <li className="slide-up">67 W 89th St #1F New York,</li>
                </div>
                <div className="hidden">
                  <li className="slide-up">NY 10024</li>
                </div>
              </ul>
            </div>

            <div className="bangkok">
              <h2 className="bkkText">BANGKOK</h2>
              <img className="plusBkk" src="/assets/plus_gold.svg" alt="" />
              <ul className="bkkContactLeft">
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="tel:0818337474">+66 81 833 7474</a>
                  </li>
                </div>
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="tel:027128010">+66 2 712 8010 #7426</a>
                  </li>
                </div>
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="mailto:hr@conspiracyplus.com">
                      hr@conspiracyplus.com
                    </a>
                  </li>
                </div>
              </ul>

              <ul className="bkkContactRight">
                <div className="hidden">
                  <li className="slide-up">Conspiracy Co.,Ltd.</li>
                </div>
                <div className="hidden">
                  <li className="slide-up">Racquet Club Building</li>
                </div>
                <div className="hidden">
                  <li className="slide-up">room 356, 145 Sukhumvit 49/9,</li>
                </div>
                <div className="hidden">
                  <li className="slide-up">Sukhumvit Road,Klongton Nua,</li>
                </div>
                <div className="hidden">
                  <li className="slide-up">Wattana, Bangkok 10110</li>
                </div>
              </ul>
            </div>

            <div className="facebook">
              <img className="plusFb" src="/assets/plus_gold.svg" alt="" />
              <img className="fbIcon" src="/assets/fb_icon.svg" />

              <ul className="fbContact">
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="https://www.facebook.com/Conspiracyplus/">
                      Conspiracy Co.,Ltd.
                    </a>
                  </li>
                </div>
                <div className="hidden">
                  <li className="slide-up">
                    <a className="a-link" href="https://www.facebook.com/Conspiracyplus/">
                      @Conspiracyplus
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>

          <div className="wrapper-description-mobile">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1244.8 339.75">
              <g id="Layer_2" data-name="Layer 2">
                <g id="defult">
                  <g id="g-con-mobile">
                    <path
                      id="ch-c-1"
                      d="M49.7,0C20.1.09,0,15.39,0,46.7V171.43H49.7c-.48,0-.52,0,0,0-14.32-2.88-21.2-20.32-21.2-36.22v-99C28.5,20.32,35.38,2.89,49.7,0"
                    />
                    <path
                      id="ch-c-2"
                      d="M109.46,130.63a39.23,39.23,0,0,1-8,23.5,53.87,53.87,0,0,1-29.78,19.75c8.59-1.5,16.15-4.39,23.28-7.21a135.46,135.46,0,0,1,17.42-6.06c3.42-.83,16.11-4.08,22.61,3.86h2.84l.07-54.3H109.47Z"
                    />
                    <path
                      id="ch-c-3"
                      d="M101.46,19.75a39.23,39.23,0,0,1,8,23.5V63.71H137.9l-.07-54.3H135c-6.49,8-19.19,4.68-22.6,3.86A134.59,134.59,0,0,1,95,7.21C87.83,4.39,80.27,1.49,71.68,0a53.85,53.85,0,0,1,29.78,19.75"
                    />
                    <path
                      id="ch-o"
                      d="M264.51,173.53c-54.29,0-85.34-39.12-85.34-85.82S210.22,2.37,264.51,2.37C319,2.37,349.84,41,349.84,87.71S319,173.53,264.51,173.53Zm0-168c-35.7,0-47.19,35-47.19,82.4,0,47,11.49,82.4,47.19,82.4s47.18-35,47.18-82.4C311.69,41,300.2,5.55,264.51,5.55Z"
                    />
                    <path
                      id="ch-n"
                      d="M530,171.82,413.89,23.16V108c0,29.34,11,58.44,27.14,59.17l5.14.25v2.93H389.68v-2.93l2.69-.25c11-1,17.85-19.31,17.85-59.17V43.45c0-31.54-4.89-34.23-16.13-34.72l-4.41-.24V5.55h55.26l99.27,128.86V66c0-28.12-10.51-56.49-27.14-57.22l-5.13-.24V5.55h56.48V8.49l-2.69.24C554,9.71,547.88,28.29,547.88,66V171.82Z"
                      fill="none"
                      stroke="#927c60"
                      strokeMiterlimit="10"
                    />
                  </g>

                  <g id="g-tact-mobile">
                    <path
                      id="ch-t-1"
                      d="M588.07,336.57v-2.94l5.14-.24c11-.49,13.45-3.42,13.45-36.92V211.38c0-33-1.47-36.43-11.25-36.43h-4.16c-14.42,0-28.36,13.45-38.14,43.28l-2.69,8.31H548V171.77H700.3v54.77h-2.2l-2.93-8.31C685.39,188.4,671.45,175,657,175h-4.15c-9.54,0-11,3.42-11,36.43v85.09c0,33.5,2.44,36.43,13.2,36.92l5.13.24v2.94Z"
                      fill="none"
                      stroke="#927c60"
                      strokeMiterlimit="10"
                    />
                    <path
                      id="ch-a"
                      d="M810.57,336.57v-2.94l6.36-.24c13-.49,11.73-6.85.24-37.41l-9-23.72H753.36l-3.43,9.54a83.74,83.74,0,0,0-5.62,30.07c0,11.74,4.16,21,15.4,21.52l5.14.24v2.94h-55v-2.94l2.69-.24c9.78-.73,15.9-5.14,33.75-52.57L788.08,170.3h15.65l50.61,124c14.18,35,17.36,37.9,26.89,39.12l2,.24v2.94Zm-56-67.24H806.9L780.5,199.4Z"
                    />
                    <path
                      id="ch-c"
                      d="M1048,339.75c-8.8-8.32-14.67-10.76-19.56-10.76-3.42,0-6.36,1.22-10.76,3.67-7.33,4.4-15.89,7.09-26.89,7.09-50.62,0-80.2-39.61-80.2-85.34,0-45.48,28.85-85.82,78.73-85.82a53.34,53.34,0,0,1,26.89,6.85c4.16,2.2,6.85,3.66,10,3.66,4.65,0,9.29-2.69,16.87-10.51h2v54h-2.45l-2.69-8.32c-7.33-22.73-25.18-42.54-48.9-42.54-32.27,0-42.3,35.45-42.3,82.4s10.51,82.4,43.77,82.4c25.18,0,42.79-22.25,52.08-49.88l2.93-8.32h2.45v61.38Z"
                    />
                    <path
                      id="ch-t-2"
                      d="M1132.57,336.57v-2.94l5.14-.24c11-.49,13.45-3.42,13.45-36.92V211.38c0-33-1.47-36.43-11.25-36.43h-4.16c-14.42,0-28.36,13.45-38.14,43.28l-2.69,8.31h-2.45V171.77H1244.8v54.77h-2.2l-2.93-8.31C1229.89,188.4,1216,175,1201.52,175h-4.15c-9.54,0-11,3.42-11,36.43v85.09c0,33.5,2.44,36.43,13.2,36.92l5.13.24v2.94Z"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <div className="wrapper">

              {/* <div className="img-contact">
                <img className="img-contact" src="/assets/g_contact.svg" alt="" />
              </div> */}
              <div className="new-york">
                <div className="hidden">
                  <h2 className="slide-left">new york</h2>
                </div>

                <ul>
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="tel:+19173740409">+19 17 374 0409</a>
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="mailto:ny@conspiracyplus.com">
                        ny@conspiracyplus.com
                      </a>
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">67 W 89th St #1F New York,</li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">NY 10024</li>
                  </div>
                </ul>
              </div>

              <div className="bangkok">
                <div className="hidden">
                  <h2 className="slide-left">bangkok</h2>
                </div>

                <ul>
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="tel:0818337474">+66 81 833 7474</a>
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="tel:027128010">+66 2 712 8010 #7426</a>
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="mailto:hr@conspiracyplus.com">
                        hr@conspiracyplus.com
                      </a>
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">Conspiracy Co.,Ltd.</li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">Racquet Club Building</li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">
                      room 356, 145 Sukhumvit 49/9,
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">
                      Sukhumvit Road, Klongton Nua,{" "}
                    </li>
                  </div>
                  <div className="hidden">
                    <li className="slide-left">Wattana, Bangkok 10110</li>
                  </div>
                </ul>
              </div>

              <div className="facebook">
                <div className="hidden">
                  <img
                    className="slide-left fbIcon"
                    src="/assets/fb_icon.svg"
                  />
                </div>
                <ul className="fbContact">
                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="https://www.facebook.com/Conspiracyplus/">
                        Conspiracy Co.,Ltd.
                      </a>
                    </li>
                  </div>

                  <div className="hidden">
                    <li className="slide-left">
                      <a className="a-link" href="https://www.facebook.com/Conspiracyplus/">
                        @Conspiracyplus
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}