import React from "react";
import "../scss/WhatWeDo.scss";
import VanillaTilt from "vanilla-tilt";
import * as ScrollMagic from "scrollmagic";
import "../js/debug.addIndicators.js";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
  
const $ = window.$;

const divStyle = {
    whiteSpace: 'pre-line',
    
    // color: 'red'
  };

export default class WhatWeDo extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
        this.tl = new TimelineMax({ paused: false });
    }

    componentDidMount() {

        const slide = document.querySelectorAll(".slide-we-do");

        if($(window).width() > 1024) {
            // console.log('>1024 ', $(window).width())
            new ScrollMagic.Scene({
                triggerElement: ".section-whatwedo",
                triggerHook: 0.5,
                reverse: false
            })
                .setTween(
                    this.tl
                        .to(".section-whatwedo .space", 0.4, {
                            transform: "scaleX(0.5)",
                            ease: Expo.easeInOut
                        })
                        .to(".section-whatwedo .space", 0.4, {
                            transform: "rotate(45deg) scaleX(0.5)",
                            ease: Expo.easeInOut
                        })
                        .staggerFrom(
                            ".section-whatwedo .box-left svg path",
                            0.4,
                            {
                                opacity: 0,
                                xPercent: 100,
                                delay: 1,
                                ease: Expo.easeOut
                            },
                            0.1,
                            "-=0.4"
                        )
    
                        .staggerFrom(
                            slide,
                            0.8,
                            {
                                opacity: 0,
                                xPercent: -100,
                                ease: Expo.easeOut
                            },
                            0.2,
                            "-=0.2"
                        )
                )
                // .addIndicators({ name: "what we do..." })
                .addTo(this.controller);
        } else {
            // console.log('<1024', $(window).width())
            new ScrollMagic.Scene({
                triggerElement: ".section-whatwedo",
                triggerHook: 0.6,
                reverse: false
            })
                .setTween(
                    this.tl
                        .staggerFrom(
                            ".section-whatwedo .box-left svg path",
                            0.6,
                            {
                                opacity: 0,
                                xPercent: 100,
                                // delay: 1.2,
                                ease: Expo.easeInOut
                            },
                            0.1,
                            "-=0.8"
                        )
    
                        .staggerFrom(
                            slide,
                            0.8,
                            {
                                opacity: 0,
                                yPercent: 100,
                                ease: Expo.easeInOut
                            },
                            0.2,
                            "-=0.2"
                        )
                )
                // .addIndicators({ name: "what we do..." })
                .addTo(this.controller);
        }
        
        
    }

    // componentDidUpdate() {
    //     VanillaTilt.init(document.querySelector(".slide-we-do"), {
    //         max: 50,
    //         speed: 3000,
    //         scale: 1.2,
    //     })
    // }

    render() {
        return (
            <section className="section-whatwedo" id="whatwedo">
                <div className="container">
                    <div className="box-left">
                        <div className="hidden">
                            {/* <img src="/assets/what_we_do.svg" alt="" /> */}
                            
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="557.6543"
                                height="195.2847"
                                viewBox="0 0 557.6543 195.2847"
                            >
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="AW">
                                        <path
                                            d="M100.5361,120.5278,73.6885,44.6963,49.6968,120.5278H40.4146L18.9932,48.2666c-6.1407-20.85-7.9971-22.4209-14.2808-23.1348L3.57,24.9893V23.2754H45.8408v1.7139l-3.2842.1425c-7.8545.2862-7.8545,4.2842-2.7133,21.9922L54.41,97.9639,78.1152,22.2754h9.2827l26.2764,76.1162,11.2818-42.1279a81.3811,81.3811,0,0,0,2.4277-18.2793c0-7.4258-2.856-12.5664-9.7109-12.8526l-2.9991-.1425V23.2754h31.5606v1.7139l-1.5708.1425c-6.4263.5713-10.1392,5.1416-17.4224,31.7032l-17.4228,63.6928Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M234.2021,119.5278V117.814l2.9991-.1426c6.4262-.2861,7.8545-1.999,7.8545-21.564V70.9727h-45.27V96.1074c0,19.565,1.4283,21.2779,7.8545,21.564l2.9991.1426v1.7138h-42.128V117.814l2.9986-.1426c6.2837-.2861,7.7119-1.999,7.7119-21.564V46.6953c0-19.5644-1.4282-21.2773-7.7119-21.5635l-2.9986-.1425V23.2754h42.128v1.7139l-2.9991.1425c-6.4262.2862-7.8545,1.9991-7.8545,21.5635V69.26h45.27V46.6953c0-19.5644-1.4283-21.2773-7.8545-21.5635l-2.9991-.1425V23.2754H276.33v1.7139l-2.9986.1425c-6.2836.2862-7.7119,1.9991-7.7119,21.5635V96.1074c0,19.565,1.4283,21.2779,7.7119,21.564l2.9986.1426v1.7138Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M354.5854,119.5278V117.814l3.7134-.1426c7.5684-.2861,6.8545-3.999.1426-21.85l-5.2837-13.8525H321.1689l-2,5.57a48.8975,48.8975,0,0,0-3.2846,17.5649c0,6.8555,2.4277,12.2813,8.997,12.5674l2.9991.1426v1.7138H295.749V117.814l1.5713-.1426c5.7119-.4287,9.2822-2.999,19.707-30.7036l24.42-64.5489h9.14l29.561,72.4024c8.2833,20.4224,10.14,22.1362,15.709,22.85L397,117.814v1.7138ZM321.8828,80.2559h30.5606L337.0205,39.4131Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M425.8467,119.5278V117.814l2.999-.1426c6.4263-.2861,7.8545-1.999,7.8545-21.564V46.41c0-19.2793-.8569-21.2784-6.5693-21.2784h-2.4278c-8.4258,0-16.5654,7.8545-22.2778,25.2774l-1.5708,4.8554h-1.4282V23.2754H491.395V55.2646H490.11l-1.7139-4.8554c-5.7119-17.4229-13.8521-25.2774-22.2778-25.2774H463.69c-5.5693,0-6.4262,1.9991-6.4262,21.2784V96.1074c0,19.565,1.4282,21.2779,7.7114,21.564l2.999.1426v1.7138Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M100.5361,216.7036l-26.8476-75.831-23.9917,75.831H40.4146L18.9932,144.4429c-6.1407-20.85-7.9971-22.4209-14.2808-23.1348L3.57,121.1655v-1.7138H45.8408v1.7138l-3.2842.1426c-7.8545.2861-7.8545,4.2842-2.7133,21.9922L54.41,194.14l23.7055-75.6884h9.2827l26.2764,76.1162,11.2818-42.128a81.38,81.38,0,0,0,2.4277-18.2793c0-7.4257-2.856-12.5664-9.7109-12.8525l-2.9991-.1426v-1.7138h31.5606v1.7138l-1.5708.1426c-6.4263.5713-10.1392,5.1416-17.4224,31.7031l-17.4228,63.6924Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M168.5112,215.7036V213.99l2.9986-.1425c6.2837-.2862,7.7119-1.9991,7.7119-21.5635V142.8716c0-19.5645-1.4282-21.2774-7.7119-21.5635l-2.9986-.1426v-1.7138h75.4019v29.1328h-1.2852l-1.7138-4.8555c-5.4268-15.709-15.1377-22.4209-29.2754-22.4209h-4.9981c-5.998,0-6.855,2.2852-6.855,21.5635V165.436h6.5694c7.2832,0,13.5664-4.1416,16.7085-14.4238l.9995-3.1416h1.2851v36.8438H224.063l-.9995-3.1416c-3.1421-10.2823-9.4253-14.4239-16.7085-14.4239h-6.5694v25.1348c0,19.2783.857,21.5635,6.712,21.5635h7.997c15.4234,0,22.7066-7.7119,29.2759-26.7051l1.7134-4.8555h1.2851v33.417Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M383.4321,215.7036H332.7358V213.99l2.9986-.1425c6.2837-.2862,7.7119-1.9991,7.7119-21.5635V142.8716c0-19.5645-1.4282-21.2774-7.7119-21.5635l-2.9986-.1426v-1.7138h50.6963c32.417,0,50.5537,20.707,50.5537,47.9834C433.9858,194.854,415.2778,215.7036,383.4321,215.7036Zm0-94.3955H370.8652c-5.998,0-6.8549,2.2852-6.8549,21.5635v49.4121c0,19.2783.8569,21.5635,6.8549,21.5635h12.5669c20.1358,0,28.2759-20.1358,28.2759-46.27C411.708,140.8726,402.4253,121.3081,383.4321,121.3081Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                        <path
                                            d="M511.3853,217.56c-31.7032,0-49.84-22.8487-49.84-50.125s18.1367-49.84,49.84-49.84c31.8457,0,49.8393,22.5635,49.8393,49.84S543.231,217.56,511.3853,217.56Zm0-98.1084c-20.85,0-27.5621,20.4218-27.5621,48.1259,0,27.419,6.712,48.126,27.5621,48.126s27.5615-20.4219,27.5615-48.126C538.9468,140.1587,532.2349,119.4517,511.3853,119.4517Z"
                                            transform="translate(-3.5703 -22.2754)"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <span className="space"></span>
                    <div className="box-right">
                        <ul>
                            <div className="hidden">
                                <li className="slide-we-do">
                                    <h1>
                                        <span>WE </span>
                                        <span>BUILD</span>
                                    </h1>

                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {`brand and communication campaigns crafted around consumer
insights and designed to showcase your product’s truth
                                    `}
                                    </p>
                                    
                                    {/* <p style={divStyle}>brand and communication campaigns crafted around consumer
                                        insights and designed to showcase your product’s truth</p> */}
                                </li>
                            </div>

                            <div className="hidden">
                                <li className="slide-we-do">
                                    <h1>
                                        <span>WE </span>
                                        <span>CREATE</span>
                                    </h1>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {`a holistic campaign that answers 
                                        the needs of client and consumer`}
                                    </p>
                                </li>
                            </div>

                            <div className="hidden">
                                <li className="slide-we-do">
                                    <h1>
                                        <span>WE </span>
                                        <span>DESIGN</span>
                                    </h1>
                                    {/* <p style={{ whiteSpace: 'pre-line'  }}>
                                        {`create and execute work that 
                                        brings your vision to life`}
                                    </p> */}
                                    <p>create and execute work that brings your 
                                        vision to life</p>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}
