import React, { Component } from "react";
import { TweenMax as TM } from "gsap/all";
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import HorizontalScrollPlugin from "../js/HorizontalScrollPlugin";
import "../scss/Cave.scss";
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Expo } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "../js/debug.addIndicators.js";

Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);
const $ = window.$;
const offsetTitle = 100;
const map = (value, min1, max1, min2, max2) =>
    min2 + ((max2 - min2) * (value - min1)) / (max1 - min1);

export default class Cave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        };

        this.controller = new ScrollMagic.Controller();
        this.tl = new TimelineMax({ paused: false });
    }

    componentDidMount() {
        const s = Scrollbar.init(document.querySelector(".scrollarea"), {
            // delegateTo: document,
            continuousScrolling: false,
            overscrollEffect: "bounce",
            damping: 0.05,
            plugins: {
                horizontalScroll: {
                    events: [/wheel/]
                }
            }
        });

        // Scrollbar.track.xAxis.element.remove();
        // Scrollbar.track.yAxis.element.remove();

        s.addListener(scroll => {
            // console.log('yeah')
            this.onScroll(scroll);
        });

        // --------------------------------------------------------------------

        const img = document.querySelectorAll(
            ".section-cave .container-desktop img"
        );

        const span = document.querySelectorAll(
            ".section-cave .container-desktop span"
        );

        // console.log('imggg',img)

        if($(window).width() > 1080) {
            new ScrollMagic.Scene({
                triggerElement: ".section-cave",
                triggerHook: 0.2,
                reverse: false
            })
                .setTween(
                    this.tl
                        .staggerFrom(img, 1.75, {
                            opacity: 0,
                            y: 50,
                            ease: Expo.easeOut
                        }, 0.4, "-=0.2")
                        .staggerFrom(span, 0.8, {
                            opacity: 0,
                            ease: Expo.easeOut
                        }, 0.2, "-=1.75")
                )
                // .addIndicators({ name: "mobile" })
                .addTo(this.controller);
        } else {
            // console.log('<1080')
            new ScrollMagic.Scene({
                triggerElement: ".section-cave",
                triggerHook: 0.5,
                reverse: false
            })
                .setTween(
                    this.tl
                        .staggerFrom(img, 1.75, {
                            opacity: 0,
                            y: 50,
                            ease: Expo.easeOut
                        }, 0.4, "-=0.2")
                        .staggerFrom(span, 0.8, {
                            opacity: 0,
                            ease: Expo.easeOut
                        }, 0.2, "-=1.75")
                )
                // .addIndicators({ name: "mobile" })
                .addTo(this.controller);
        }

        
        
    }

    onScroll({ limit, offset }) {
        this.state.progress = offset.x / limit.x;

        const title = document.querySelector(".page-title");

        TM.to(title, 0.3, {
            x: -this.state.progress * offsetTitle,
            force3D: true
        });
        this.updateScrollBar();
    }

    updateScrollBar() {
        const progress = map(this.state.progress * 100, 0, 100, 5, 100);

        const progressSlide = document.querySelector(".slideshow__progress");

        TM.to(progressSlide, 0.3, { xPercent: progress, force3D: true });
    }

    render() {
        return (
            <>
                <section className="section-cave" id="office">
                    <div className="container-desktop">
                        <div className="grid-7">
                          {/* <div className="image vh-fix"></div> */}
                            <span className="sq-1"></span>
                            <span className="sq-2"></span>
                            <span className="sq-3"></span>

                            <div className="img-c1">
                                <img
                                    className="img-1"
                                    src="/assets/c1.jpg"
                                    alt=""
                                />
                            </div>
                            
                            <img
                                className="img-2"
                                src="/assets/c2_1.jpg"
                                alt=""
                            />

                            <img
                                className="img-cave"
                                src="/assets/our_cave-3.svg"
                                alt=""
                            />
                            <img
                                className="img-office"
                                src="/assets/office.svg"
                                alt=""
                            />

                            <img
                                className="img-3"
                                src="/assets/c3_1.jpg"
                                alt=""
                            />
                            <img
                                className="img-4"
                                src="/assets/c4.jpg"
                                alt=""
                            />
                            <img
                                className="img-5"
                                src="/assets/c6.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </section>

                <section className="section-cave-mobile-wrapper" id="office-1">
                    <div className="page-title | title">
                        <img
                            src="/assets/our_cave-2.svg"
                            alt=""
                            className="img-title"
                        />
                    </div>
                    <section
                        className="section-cave-mobile | scrollarea-ctn"
                        id="office-1"
                    >
                        <div className="scrollarea | slideshow">
                            <ul className="slideshow-list">
                                <li className="slideshow-list__el">
                                    <article className="tile | js-tile">
                                        <figure className="tile__fig">
                                            <img
                                                src="/assets/c6.jpg"
                                                className="tile__img"
                                            />
                                            {/* <img
                                                src="/assets/c1.jpg"
                                                className="tile__img"
                                            /> */}
                                        </figure>
                                    </article>
                                </li>

                                <li className="slideshow-list__el">
                                    <article className="tile | js-tile">
                                        <figure className="tile__fig">
                                            {/* <img
                                                src="/assets/c3_1.jpg"
                                                className="tile__img"
                                            /> */}
                                            <img
                                                src="/assets/c4.jpg"
                                                className="tile__img"
                                            />
                                        </figure>
                                    </article>
                                </li>

                                <li className="slideshow-list__el">
                                    <article className="tile | js-tile">
                                        <figure className="tile__fig">
                                        <img
                                                src="/assets/c3_1.jpg"
                                                className="tile__img"
                                            />
                                            {/* <img
                                                src="/assets/c2_large.jpg"
                                                className="tile__img"
                                            /> */}
                                        </figure>
                                    </article>
                                </li>

                                <li className="slideshow-list__el">
                                    <article className="tile | js-tile">
                                        <figure className="tile__fig">
                                        <img
                                                src="/assets/c2_large.jpg"
                                                className="tile__img"
                                            />
                                        {/* <img
                                                src="/assets/c3_1.jpg"
                                                className="tile__img"
                                            /> */}
                                            {/* <img
                                                src="/assets/c4.jpg"
                                                className="tile__img"
                                            /> */}
                                        </figure>
                                    </article>
                                </li>

                                <li className="slideshow-list__el">
                                    <article className="tile | js-tile">
                                        <figure className="tile__fig">
                                             <img
                                                src="/assets/c1.jpg"
                                                className="tile__img"
                                            />
                                            {/* <img
                                                src="/assets/c6.jpg"
                                                className="tile__img"
                                            /> */}
                                        </figure>
                                    </article>
                                </li>
                            </ul>
                        </div>
                        <div className="slideshow__progress-ctn">
                            <span className="slideshow__progress"></span>
                        </div>
                    </section>
                </section>
            </>
        );
    }
}
